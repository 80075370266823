import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './AlternatingTextAndImageBlocks.scss';

const AlternatingTextAndImageBlocks = ({ slice }) => {
  const {
    title,
  } = slice.primary;

  return (
    <section className="alternating-text-and-image-blocks-container" id="alternating-text-and-image-blocks-container">
      <div className="alternating-text-and-image-blocks-title">{asText(title.raw)}</div>
      {slice.items.map((item, index) => {
        const {
          image_position, image_below_text, image, text,
        } = item;
        const prismicImage = getImage(image.localFile);
        const prismicImageBelowText = getImage(image_below_text.localFile);
        return (
          <div className="alternating-text-and-image-blocks" key={index}>
            <div className={`alternating-text-and-image-blocks ${image_position}`}>
              <div className="text-above-image">
                <div className="text-above-image-mobile">{asText(text.raw)}</div>
                <GatsbyImage
                  image={prismicImage}
                  alt={prismicImage.alt || ''}
                  loading="eager"
                  objectFit="contain"
                />
              </div>
              <div className="image-below-text">
                <div className="text-above-image-desktop">{asText(text.raw)}</div>
                <GatsbyImage
                  image={prismicImageBelowText}
                  alt={prismicImageBelowText.alt || ''}
                  loading="eager"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default AlternatingTextAndImageBlocks;
