import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import LearnMore from '../../LearnMore/LearnMore';
import './ConceptSnippet.scss';

const ConceptSnippet = ({ slice }) => {
  const {
    title_color,
    title,
    description,
    description_color,
  } = slice.primary;

  return (
    <section className="concept-snippet" id="concept-snippet">
      <div className={`title ${title_color}`}>
        <PrismicRichText field={title.raw || []} />
      </div>
      {description && description.text.length > 0 && (
        <div className={`title-description ${description_color}`}>
          {asText(description.raw)}
        </div>
      )}
      <div className="snippet">
        {slice.items.map((concept, index) => {
          const { icon } = concept;
          const image = getImage(icon.localFile);
          return (
            <div className={`one-snippet ${index % 2 === 0 ? '' : 'odd'}`} key={index}>
              <div className="hover-wrapper">
                <div className="icon-wrapper">
                  <div className="icon">
                  {icon.localFile?.childImageSharp ?
                    <GatsbyImage
                      image={image}
                      alt={icon.alt || ''}
                      loading="eager"
                      objectFit="contain"
                    />
                    :
                    <img
                      src={icon.localFile?.publicURL}
                      alt={icon.alt || ''}
                      width={70}
                    />
                  }
                  </div>
                  <span className="hover-circle" />
                </div>
                <div className="snippet-title">
                  {asText(concept.title.raw).toUpperCase()}
                </div>
              </div>
              <div className="description">
                {asText(concept.description.raw)}
              &nbsp;
                {concept.learn_more
                && <LearnMore url={concept.url.document?.url} type={concept.learn_more} fragment={concept.fragment} />}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default ConceptSnippet;
