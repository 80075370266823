import React from 'react';
import { Link } from 'gatsby';
import './LearnMore.scss';

const LearnMore = (props) => {
  const { url, type, fragment } = props;

  return (
    <Link to={fragment ? `${url}#${fragment}` : url || undefined}>
      <span className={`${type}`}>
        {type !== 'none' && type.replace(/-/g, ' ').toUpperCase()}
      </span>
    </Link>
  );
};
export default LearnMore;
