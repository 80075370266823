import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import './_full-width-media.scss';
import { Link } from 'gatsby';

export default function Stream() {
  return (
    <div className="stream-wrapper">
      <div className="stream">STREAM THE LATEST EPISODE BELOW</div>
      <div className="other-ways">OTHER WAYS TO LISTEN</div>
      <Link to="https://open.spotify.com/show/3UaMnZNi0ycmMhjNZ07eaT" target="_blank">
        <StaticImage
          className="spotify-logo"
          src="../../../images/icons/spotify-logo.png"
          alt="spotify"
        />
      </Link>
      {/* <StaticImage
        className="apple-podcast-logo"
        src="../../../images/icons/apple-podcast-logo.png"
        alt="apple-podcast"
      /> */}
    </div>
  );
}
