import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import './Button.scss';
import { Link } from 'gatsby';

export default function Button() {
  return (
    <div className="button-container">
      <Link className="link" to="/newsletter">
        <div className="subscribe-to-newsletter-button">
          <div className="text">Subscribe to newsletter</div>
          <StaticImage
            className="image"
            src="../../images/newsletter.png"
            alt="dropdown"
            objectFit="contain"
          />
        </div>
      </Link>
    </div>
  );
}
