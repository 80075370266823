import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import './FullWidthVideo.scss';

const FullWidthVideo = ({ slice, blogInfo, type }) => {
  const {
    title,
    title_color,
    description,
    description_color,
    title_above_description,
    video,
  } = slice.primary;

  return (
    <section className="full-width-video-container">
      <div className="full-width-video-bg-overlay">
        <video muted autoPlay loop playsInline>
          <source src={video.localFile?.publicURL} type="video/mp4" />
        </video>
      </div>
      <div className={`full-width-video-text-container ${title_above_description ? 'title-above' : 'title-below'}`}>
        {title.text.length ? <div className="full-width-video-title" style={{ color: title_color }}>{asText(title.raw)}</div> : null}
        {description.text.length ? <div className="full-width-video-description" style={{ color: description_color }}>{asText(description.raw)}</div> : null}
      </div>
    </section>
  );
};

export default FullWidthVideo;
