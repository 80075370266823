import React, { useEffect, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './HorizontallyExpandableList.scss';

const HorizontallyExpandableList = ({ slice }) => {
  const [expand, setExpand] = useState(false);
  const [onSecondClick, setOnSecondClick] = useState(false);
  const [descriptionIndex, setDescriptionIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState('');
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  const {
    title,
    title_color,
    description,
    description_color,
    size,
  } = slice.primary;

  useEffect(() => {
    setExpand(true);
    setDescriptionIndex(1);
    setOnSecondClick(false);
  }, []);

  useEffect(() => {
    if (!isBrowser) return false;
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (width < 769) {
      setOnSecondClick(true);
    } else {
      setOnSecondClick(false);
    }
  }, [width]);

  const expandList = (item, index, url) => {
    if (selectedItem === item) {
      setExpand(!expand);
      setOnSecondClick(true);
      redirect(url);
    } else {
      setExpand(true);
      if (descriptionIndex === index) {
        setOnSecondClick(true);
        redirect(url);
      }
    }
    setDescriptionIndex(index);
    setSelectedItem(item);
  };

  const redirect = (url) => {
    setExpand(true);
    setDescriptionIndex(1);
    setOnSecondClick(false);
    setSelectedItem('');
    if (isBrowser) {
      window.location = url;
    }
  };

  return (
    <section className="horizontally-expandable-list" id="case-studies">
      <div className={`title ${title_color}`}>
        <PrismicRichText field={title.raw || []} />
      </div>
      <div className={`description ${description_color}`}>
        {asText(description.raw).toUpperCase()}
      </div>
      <div className={`list-container ${size ? 'large' : 'normal'}`}>
        {slice.items.map((item, index) => {
          const expandSpecificItem = expand && descriptionIndex === index;
          const image = getImage(item.list_media.localFile);
          return (
            <a
              className={`list ${expandSpecificItem ? 'expand' : 'closed'}`}
              onClick={() => expandList(item, index, item.link.document.url)}
              key={index}
              onKeyDown={() => (expandSpecificItem ? '' : expandList(item, index, item.link.document.url))}
              role="button"
              tabIndex={index}
              href={onSecondClick ? item.link.document.url : undefined}
            >
              <GatsbyImage
                image={image}
                alt=""
                loading="eager"
                className="image"
              />
              <div className={`text-on-top ${expandSpecificItem ? 'expand' : 'closed'}`}>
                <div className="list-title">
                  {asText(item.list_title.raw).toUpperCase()}
                </div>
                <div className="list-description">
                  {asText(item.list_description.raw).toUpperCase()}
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </section>
  );
};

export default HorizontallyExpandableList;
