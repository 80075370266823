import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import './TextInfo.scss';

const TextInfo = ({ slice, type, metaTitle, isArticle }) => {
  const {
    title,
    title_color,
    title_alignment,
    description_alignment,
    description_width,
    description_color,
    description,
    description_html,
    icon,
    dot_line,
    width,
    pdf_text,
    pdf_hyperlink,
    styled_html,
    center_vertically,
    font_family,
    padding_top,
    background_color,
    temp_nudgestock_styling,
  } = slice.primary;

  const image = icon && getImage(icon.localFile);
  const styledImage = styled_html && getImage(styled_html.localFile);
  function createMarkup() {
    const output = description_html && description_html.text ? description_html.text.replace(/(?:&nbsp;)(?:\\[rn]|[\r\n])/g, '<br/><br/>') : '';
    return { __html: output };
  }

  const addBreaks = (raw) => {
    if (!raw) { return ''; }
    return raw.map((r) => {
      if (!r.text.length) {
        r.text = '\n';
      }
      return r;
    });
  };

  const isBlogOrArticle = type === 'blog_post' || type === 'knowledge_center_article';
  const isNudgestock = type === 'page' && (metaTitle.includes('Nudgestock |') || metaTitle.includes('Nudgestock 22'));

  return (
    <section className={`text-info ${width} ${isArticle ? 'is-article' : ''} ${padding_top}-padding-top`} style={{ backgroundColor: background_color, padding: background_color ? '5%' : '' }}>
      {title.text && (
        <div id="title" className={`title ${title_color} ${title_alignment} ${width} ${isBlogOrArticle ? 'blog' : isNudgestock ? 'nudgestock' : ''} font-${font_family}`}>
          <span>
            <PrismicRichText field={title.raw || []} />
          </span>
        </div>
      )}
      {image && (
        <GatsbyImage
          className="icon"
          image={image}
          alt="icon"
          objectFit="contain"
        />
      )}
      <div className={`description-container ${description_alignment} ${description_width} font-${font_family}`}>
        {dot_line
          && (
            <StaticImage
              className={`dot-line ${description_alignment}`}
              src="../../../images/dot-line.png"
              alt="dotline"
            />
          )}
        <div className={`description ${description_color} ${description_alignment} ${isBlogOrArticle ? 'blog' : ''} font-${font_family} ${temp_nudgestock_styling ? 'nudgestock' : 'normal'}`}>
          {slice?.items?.length ? slice.items.map((item, index) => {
            const alignedImage = item.image_with_text && getImage(item.image_with_text.localFile);
            return (
              <div key={index} className={`align-${item.image_alignment}`}>
                <GatsbyImage
                  className="icon"
                  image={alignedImage}
                  alt="icon"
                  objectFit="contain"
                />
                <p className="aligned-text"><PrismicRichText field={addBreaks(item.text_with_aligned_image?.raw)} /></p>
              </div>
            );
          }) : null}
          {description_html?.text && !description?.text.length && (
            <div className="inner-html" dangerouslySetInnerHTML={createMarkup()} />
          )}
          {!description_html?.text && description && (
            <p><PrismicRichText field={addBreaks(description.raw) || []} /></p>
          )}
          {pdf_text && pdf_hyperlink
            && (
              <a className="hyperlink" href={pdf_hyperlink.url} download>
                <PrismicRichText field={pdf_text.raw || []} />
              </a>
            )}
          {styled_html && styledImage && (
            <GatsbyImage image={styledImage} alt="styled html as image" />
          )}
        </div>
      </div>
    </section>
  );
};

export default TextInfo;
