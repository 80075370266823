import React, { useState } from 'react';
import { asText } from '@prismicio/helpers';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

const MinimalForm = ({ consent, onSend, portal_id, form_id, why_are_you_contacting_us, google_conversion_name }) => {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const [captchaValue, setCaptchaValue] = useState("");
  const [notVerified, setNotVerified] = useState(false);

  const [formData, setFormData] = useState({
    fields: [
      {
        name: 'firstname',
        value: '',
      },
      {
        name: 'email',
        value: '',
      },
      {
        name: 'please_tell_us_why_you_are_contacting_us_',
        value: '',
      }
    ]
  })

  const handleHubspotSubmit = (event) => {
    if (captchaValue?.length) {
      const timestamp = new Date();
      const milliseconds = timestamp.getTime();

      const xhr = new XMLHttpRequest();
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portal_id}/` + form_id;

      formData.submittedAt = milliseconds.toString();
      formData.legalConsentOptions = consent;
      const finalData = JSON.stringify(formData);

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          onSend();
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(xhr.responseText); // Returns a 404 error if the formGuid isn't found
        }
      };
      xhr.send(finalData);
      window.dataLayer.push({
        'event': google_conversion_name,
        'hs-form-guid': form_id,
      });
    } else {
      setNotVerified(true);
    }
  };

  const setData = (key, value) => {
    const objIndex = formData.fields.findIndex(((obj) => obj.name === key));
    formData.fields[objIndex].value = value;
  };

  const firstNameRegister = register('firstname', { required: true });
  const emailRegister = register('email', { required: true });
  const messageRegister = register('please_tell_us_why_you_are_contacting_us_', { required: true });

  const onReCAPTCHA = (value) => {
    if (value) {
      setNotVerified(false);
    }
    setCaptchaValue(value);
  }

  return (
    <form className="form" onSubmit={handleSubmit(handleHubspotSubmit)}>
      <div className="minimal-form">
        <input
          className={`name ${errors.firstname?.type}`}
          type="text"
          placeholder="NAME"
          {...firstNameRegister}
          onChange={(e) => {
            firstNameRegister.onChange(e);
            setData('firstname', e.target.value);
          }}
        />
        <input
          className={`email ${errors.email?.type}`}
          type="text"
          placeholder="EMAIL"
          {...emailRegister}
          onChange={(e) => {
            emailRegister.onChange(e);
            setData('email', e.target.value)
          }}
        />
        <textarea
          className={`message ${errors.please_tell_us_why_you_are_contacting_us_?.type}`}
          type="text"
          placeholder={why_are_you_contacting_us ? asText(why_are_you_contacting_us.raw)?.toUpperCase() : 'MESSAGE'}
          {...messageRegister}
          onChange={(e) => {
            messageRegister.onChange(e);
            setData('please_tell_us_why_you_are_contacting_us_', e.target.value)
          }}
        />
        <div className="button-wrapper">
          <input
            className="submit"
            type="submit"
            value="SUBMIT"
          />
          <div className={`captcha ${notVerified ? 'not-verified' : 'verified'}`}>
            <ReCAPTCHA
              sitekey="6LfsCP8fAAAAANoUgdFHzLdc7CxAUkPLd-lfPA77"
              onChange={onReCAPTCHA}
              className="captcha"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default MinimalForm;