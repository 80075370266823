import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import Button from '../components/Button/Button';

const KnowledgeCenterArticleTemplate = ({ data }) => {
  if (!data) return null;

  const knowledgeCenterArticle = data.prismicKnowledgeCenterArticle;
  const knowledgeCenterArticleContent = knowledgeCenterArticle.data || {};

  const { lang, type, url } = knowledgeCenterArticle;
  const alternateLanguages = knowledgeCenterArticle.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = knowledgeCenterArticleContent.meta_title || `${knowledgeCenterArticleContent.display_title.text} | CloudArmy Knowledge Center`;
  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone
        slices={knowledgeCenterArticleContent.body}
        type={knowledgeCenterArticle.type}
      />
      <div style={{ height: '20px', marginBottom: '20px' }} />
      <Button />
    </Layout>
  );
};

export const query = graphql`
  query KnowledgeCenterArticleQuery($uid: String, $lang: String, $id: String) {
    prismicKnowledgeCenterArticle(uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      url
      type
      tags
      data {
        body {
          ... on PrismicKnowledgeCenterArticleDataBodyTextInfo {
            id
            slice_label
            slice_type
            primary {
              description_alignment
              description_color
              description_html {
                text
              }
              title_alignment
              title_color
              width
              font_family
              title {
                html
                text
                raw
              }
              icon {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              styled_html {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              description {
                html
                raw
                text
              }
            }
          }
          ... on PrismicKnowledgeCenterArticleDataBodyEmbeddedMedia {
            id
            slice_label
            slice_type
            primary {
              embed_url
              height
              title_placement
              width
              youtube_id
              alt
              title {
                html
                text
                raw
              }
              media {
                raw
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicKnowledgeCenterArticleDataBodyFormWithImage {
            id
            slice_type
            slice_label
            primary {
              title {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                raw
                richText
                text
              }
              portal_id
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              google_conversion_name
              form_id
            }
            items {
              area_of_interest
            }
          }
          ... on PrismicKnowledgeCenterArticleDataBodyBasicForm {
            id
            slice_label
            slice_type
            primary {
              form_id
              google_conversion_name
              portal_id
              title {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                raw
                richText
                text
              }
              description {
                html
                raw
                richText
                text
              }
            }
          }
          ... on PrismicKnowledgeCenterArticleDataBodyNudgestockForm {
            id
            slice_type
            primary {
              description {
                html
                richText
                raw
                text
              }
              form_id
              google_conversion_name
              portal_id
              privacy_text {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                raw
                richText
                text
              }
              title {
                html
                raw
                richText
                text
              }
            }
            slice_label
          }
          ... on PrismicKnowledgeCenterArticleDataBodyForm {
            id
            slice_type
            slice_label
            primary {
              description {
                html
                raw
                text
              }
              fields
              title_color
              description_color
              logo_above_text
              form_id
              portal_id
              title {
                html
                raw
                text
              }
              landing_page_title_background_image {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              bottom_text {
                html
                raw
                text
              }
              why_are_you_contacting_us {
                html
                raw
                text
              }
              redirect {
                url
                document {
                  ... on PrismicPage {
                    id
                    url
                  }
                }
              }
              text_after_submit {
                html
                raw
                text
              }
              google_conversion_name
            }
            items {
              how_did_you_hear_about_us
            }
          }
        }
        date
        display_title {
          html
          raw
          text
        }
        section
        image {
          alt
          copyright
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    prismicNavigation(lang: {eq: $lang}) {
      ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(KnowledgeCenterArticleTemplate);
