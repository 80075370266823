/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions,
jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { asText } from '@prismicio/helpers';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'gatsby';
import './GridItem.scss';
import dropdownOpen from '../../../images/icons/dropdown-open-gray.png';
import dropdownClose from '../../../images/icons/dropdown-close-gray.png';

const GridItem = ({
  posts,
  relatedArticles,
  filterTypes,
  filterTags,
  searchBar,
}) => {
  // change maxItems to whatever the number of items to be displayed is
  const maxItems = 9;
  const [list, setList] = useState([...posts.slice(0, maxItems)]);
  const [savedList, setSavedList] = useState([...posts.slice(0, maxItems)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(posts.length > maxItems);
  const [searchText, setSearchText] = useState('');
  const [types] = useState([]);
  const [tags] = useState([]);
  const [showType, setShowType] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [allPostsWithType, setAllPostsWithType] = useState([]);
  const [allPostsWithTags, setAllPostsWithTags] = useState([]);
  const [searchList, setSearchList] = useState([]);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  const handleHighlight = (className, filterBy) => {
    const child = document.getElementsByClassName(className)[0].children;

    for (let i = 0; i < child.length; i++) {
      if (filterBy.includes(child[i].className)) {
        child[i].style.color = '#EDFF71';
        child[i].style.fontWeight = 'bold';
      } else {
        child[i].style.color = '#f8faf4';
        child[i].style.fontWeight = 'normal';
      }
    }
    // if none selected, all is default selected.
    if (types === filterBy && filterBy.length === 0) {
      const all = document.getElementsByClassName('all')[0];
      all.style.color = '#EDFF71';
      all.style.fontWeight = 'bold';
    }
  };

  const handleTypes = (e) => {
    const value = e.currentTarget.className;
    const index = types.indexOf(value);

    if (index > -1) {
      types.splice(index, 1);
    } else {
      types.shift();
      types.push(value);
    }

    handleHighlight('display-filters', types);
    handleTypeFiltering();
  };

  const handleTypeFiltering = () => {
    const tagList = tags.map((f) => f.replace('filter-', ''));
    let allItems = [];
    const findAllRelatedItems = posts.filter((post) => post.tags.includes(types[0]));

    if (tags.length > 0) {
      for (let i = 0; i < findAllRelatedItems.length; i++) {
        for (let j = 0; j < tagList.length; j++) {
          if (findAllRelatedItems[i].tags.includes(tagList[j]) && !allItems.includes(findAllRelatedItems[i])) {
            allItems.push(findAllRelatedItems[i]);
          }
        }
      }
    } else {
      allItems = findAllRelatedItems;
    }

    setAllPostsWithType([...allItems]);
    setSearchList([...allItems]);

    if (tags.length > 0) {
      setList([...allItems]);
      if (types.length === 0 || types[0] === 'all') {
        setList([...allPostsWithTags]);
      }
    } else if (types.length > 0) {
      if (types[0] === 'all') {
        setList([...savedList]);
      } else {
        setList([...allItems]);
      }
    } else {
      setList([...savedList]);
    }
  };

  const handleTags = (e) => {
    const value = e.currentTarget.className;
    const index = tags.indexOf(value);

    if (index > -1) {
      tags.splice(index, 1);
    } else {
      tags.push(value);
    }

    handleHighlight('display-filters', tags);
    handleTagFiltering();
  };

  const handleTagFiltering = () => {
    const tagList = tags.map((f) => f.replace('filter-', ''));

    let findAllRelatedItems;
    if (types.length > 0) {
      findAllRelatedItems = allPostsWithType.filter((post) => tagList.some((f) => post.tags.includes(f)));
      if (types[0] === 'all') {
        findAllRelatedItems = posts.filter((post) => tagList.some((f) => post.tags.includes(f)));
      }
      if (tags.length === 0) {
        findAllRelatedItems = posts.filter((post) => post.tags.includes(types[0]));
      }
    } else {
      findAllRelatedItems = posts.filter((post) => tagList.some((f) => post.tags.includes(f) || post.tags.includes(f.toUpperCase())));
    }

    setAllPostsWithTags([...findAllRelatedItems]);
    setSearchList([...findAllRelatedItems]);
    if (types.length > 0) {
      if (tags.length === 0) {
        if (types[0] === 'all') {
          setList([...savedList]);
        } else {
          setList([...findAllRelatedItems]);
        }
      } else {
        if (types[0] === 'all') {
          findAllRelatedItems = posts.filter((post) => tagList.some((f) => post.tags.includes(f)));
        } else {
          findAllRelatedItems = allPostsWithType.filter((post) => tagList.some((f) => post.tags.includes(f)));
        }
        setList([...findAllRelatedItems]);
      }
    } else if (tags.length > 0) {
      setList([...findAllRelatedItems]);
    } else {
      setList([...savedList]);
    }
  };

  const handleClickBottomTags = (tag) => {
    if (filterTypes && filterTypes.filter((e) => e.category === tag).length > 0) {
      const index = types.indexOf(tag);
      if (index > -1) {
        types.splice(index, 1);
      } else {
        types.shift();
        types.push(tag);
      }

      openType('bottom');
      handleTypeFiltering();
    } else {
      const addFilter = `filter-${tag}`;
      const index = tags.indexOf(addFilter);

      if (index > -1) {
        tags.splice(index, 1);
      } else {
        tags.push(addFilter);
      }

      openTags('bottom');
      handleTagFiltering();
    }
  };

  const handleSearchPosts = () => {
    const condition = new RegExp(searchText.toUpperCase());
    const ifFiltered = searchList.length > 0 ? searchList : posts;
    const result = ifFiltered.filter((el) => {
      const {
        display_title,
        author,
        author_title,
      } = el.data;
      let allTags;
      if (el.tags) {
        allTags = el.tags.filter((item) => item !== 'resources' && item !== 'knowledge-center');
        allTags = allTags.join().replace('-', ' ');
      }

      return condition.test(display_title.text.toUpperCase())
        || (author && condition.test(author.toUpperCase()))
        || (author_title && condition.test(author_title.toUpperCase()))
        || (allTags && condition.test(allTags.toUpperCase()));
    });
    if (searchText) {
      setList([...result]);
    } else if (types.length > 0) {
      if (types[0] === 'all') {
        setList([...savedList]);
      } else {
        setList([...searchList]);
      }
    } else if (tags.length > 0) {
      setList([...searchList]);
    } else {
      setList([...savedList]);
    }
  };

  const handleLoadMorePosts = () => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < posts.length;
      const nextResults = isMore ? posts.slice(currentLength, currentLength + 6) : [];
      setList([...list, ...nextResults]);
      setSavedList([...list]);
      setLoadMore(false);
    }
  };

  const handleHasMorePosts = () => {
    const isMore = list.length < posts.length;
    setHasMore(isMore);
  };

  useEffect(handleLoadMorePosts, [loadMore, hasMore]);
  useEffect(handleHasMorePosts, [list]);
  useEffect(handleSearchPosts, [searchText]);

  const threeRelatedArticles = (allPosts) => (relatedArticles ? allPosts.slice(0, 3) : allPosts);

  const formatDate = (unformattedDate) => {
    const date = new Date(unformattedDate);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
    return formattedDate;
  };

  const openType = (type) => {
    if (type === 'bottom') {
      setShowType(true);
      setShowTags(false);
    } else {
      setShowType(!showType);
      setShowTags(false);
    }
  };

  const openTags = (type) => {
    if (type === 'bottom') {
      setShowType(false);
      setShowTags(true);
    } else {
      setShowType(false);
      setShowTags(!showTags);
    }
  };

  return (
    <section className="grid-item">
      {relatedArticles && <div className="related-articles">RELATED ARTICLES</div>}
      {(filterTypes || filterTags)
        && (
          <div className="filter-container">
            <div className="filter-wrapper">
              {filterTypes && (
                <div className="filter-type" onClick={() => openType('top')}>
                  FILTER BY TYPE
                  <img src={showType ? dropdownClose : dropdownOpen} className={showType ? 'open' : 'close'} alt="open-dropdown" />
                </div>
              )}
              {filterTags && (
                <div className="filter-tag" onClick={() => openTags('top')}>
                  FILTER BY TAG
                  <img src={showTags ? dropdownClose : dropdownOpen} className={showTags ? 'open' : 'close'} alt="open-dropdown" />
                </div>
              )}
            </div>
            <DebounceInput
              className="search"
              minLength={1}
              debounceTimeout={500}
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="SEARCH"
            />
          </div>
        )}
      {searchBar
        && (
          <form className="search-wrapper">
            <DebounceInput
              minLength={1}
              debounceTimeout={500}
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="SEARCH"
            />
          </form>
        )}
      {(showType || showTags) && (
        <div className="display-filters">
          {showType && filterTypes.map((item, index) => (
            <div
              className={item.category}
              key={index}
              onClick={handleTypes}
              style={{ fontWeight: types.includes(item.category) ? 'bold' : 'normal', color: types.includes(item.category) ? '#EDFF71' : '#f8faf4' }}
            >
              {item.category_display.toUpperCase()}
            </div>
          ))}
          {showTags && filterTags.map((item, index) => (
            <div
              className={`filter-${item.tag}`}
              key={index}
              onClick={handleTags}
              style={{ fontWeight: tags.includes(`filter-${item.tag}`) ? 'bold' : 'normal', color: tags.includes(`filter-${item.tag}`) ? '#EDFF71' : '#f8faf4' }}
            >
              {item.tag_display.toUpperCase()}
            </div>
          ))}
        </div>
      )}
      <div className="all-items">
        {threeRelatedArticles(list).map((item, index) => {
          let image;
          if (item.data.preview_image || item.data.image) { // fix when refactor knowledge center
            image = getImage(item.data.preview_image?.localFile || item.data.image?.localFile);
          }
          let filteredTags = item.tags;
          filteredTags = filteredTags.filter((e) => e !== 'blog post' && e !== 'resources' && e !== 'knowledge-center');
          filteredTags = filteredTags.sort();
          const {
            display_title,
            author,
            date,
          } = item.data;
          return (
            <div className="container" key={index}>
              <div className="image-wrapper">
                <Link to={item.url}>
                  {image && (
                    <GatsbyImage
                      className="image"
                      image={image}
                      alt={item.data.preview_image?.alt || item.data.image?.alt || ''}
                      loading="eager"
                    />
                  )}
                  {item.data.body[0]?.primary && item.data.body[0]?.primary.youtube_id
                  && !image
                    && (
                      <div
                        className="youtube-thumbnail"
                        style={{ backgroundImage: `url("https://i.ytimg.com/vi/${item.data.body[0].primary.youtube_id}/maxresdefault.jpg")` }}
                      />
                    )}
                </Link>
              </div>
              <div className="info-wrapper">
                <Link to={item.url}>
                  <div className="title">{asText(display_title.raw).toUpperCase()}</div>
                </Link>
                {!relatedArticles && (
                  <div className="info">
                    {date && (
                      <div className="date">
                        {formatDate(date)}
                        {' '}
                        {author && `- ${author.toUpperCase()}`}
                      </div>
                    )}
                    {item.tags && (
                      <div className="tags">
                        {filteredTags.map((tag, tagIndex) => {
                          const noDashes = tag.split('-').join(' ');
                          return (
                            <span
                              className={`filter-${tag.toLowerCase()}`}
                              key={tagIndex}
                              onClick={() => handleClickBottomTags(tag)}
                              style={{ color: tags.includes(`filter-${tag.toLowerCase()}`) || types.includes(tag) ? 'white' : '#656565' }}
                            >
                              {noDashes.toUpperCase()}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {!relatedArticles
        && (list.length !== posts.length)
        && searchText.length === 0
        && tags.length === 0
        && (types.length === 0 || types[0] === 'all')
        && (
          <div className="button-wrapper">
            <button
              type="button"
              className="button"
              onClick={handleLoadMore}
            >
              LOAD MORE
            </button>
            <span className="hover-circle" />
          </div>
        )}
    </section>
  );
};
export default GridItem;
