module.exports = {
  // The repo name for your Prismic repository (found in the url of your repo: https://cloudarmy-website.prismic.io/)
  prismicRepo: 'cloudarmy-website',

  // Release ID to build (requires access)
  releaseID: 'master',

  // access token to access the release
  accessToken: 'MC5ZUGRla1JJQUFDZ0FEOGJI.aO-_ve-_vRVEA--_vRXvv73vv71y77-977-977-9PO-_vQEHZO-_vQTvv70IMnDvv71577-9I--_vTfvv70',

  customTypesApiToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImNsb3VkYXJteS13ZWJzaXRlLTE2ZjI4YjFiLTEzMGUtNDM5Yi1hN2I1LWI3OTA3NzMxMWQ3ZV80IiwiZGF0ZSI6MTYyNjczNzczMywiZG9tYWluIjoiY2xvdWRhcm15LXdlYnNpdGUiLCJpYXQiOjE2MjY3Mzc3MzN9.Ll6aGXcyPA9JofUtdX3E1aPlMwf_gYjP9Zhcn_YUxVI',

  // Language configuration in your Prismic repository: Settings / Translations & locals
  defaultLanguage: 'en-us',
  langs: ['en-us', 'fr-fr'],
  simpleCastToken: 'Bearer eyJhcGlfa2V5IjoiNzVkMzc3N2M3NWFhM2QwOTkxOWEyZTI4ZjhiM2M1YTkifQ==',
};
