import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import './EmbeddedMedia.scss';

function EmbeddedMedia({ slice, type, metaTitle }) {
  const {
    media,
    title,
    gif,
    youtube_id,
    embed_url,
    width,
    height,
    title_placement,
    title_color,
    alt,
  } = slice.primary;

  let gatsbyImageData;
  let recording;
  let isImage = false;
  if (media.localFile?.childImageSharp) {
    gatsbyImageData = getImage(media.localFile);
    isImage = true;
  } else if (media.localFile?.publicURL) {
    isImage = true;
  } else if (media.raw?.url) { // recording
    recording = media.raw.url;
  }

  const isBlogOrArticle = type === 'blog_post' || type === 'knowledge_center_article';
  const isNudgestock = type === 'page' && metaTitle.includes('Nudgestock');

  return (
    <section
      className={`embedded-media ${title_placement} ${isBlogOrArticle ? 'blog' : 'article'}`}
      style={{ maxWidth: width && (`${width}px`) }}
    >
      {title?.text && (
        <div className={`title ${title_color} ${isNudgestock ? 'nudgestock' : ''}`}>
          <PrismicRichText field={title.raw || []} />
        </div>
      )}
      <div className={`media ${title.text ? 'title' : 'no-title'} ${isImage ? 'image' : 'embed'} ${gif?.url ? 'gif' : ''}`}>
        {isImage
          ? gatsbyImageData
            ? (
              <GatsbyImage
                image={gatsbyImageData}
                alt={alt || ''}
                objectFit="contain"
                width={width}
                height={height}
              />
            )
            : (
              <div data-gatsby-image-wrapper="" className="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                <picture>
                  <img
                    width={width}
                    height={height}
                    data-main-image=""
                    style={{ objectFit: 'contain', opacity: 1, position: 'static' }}
                    decoding="async"
                    loading="lazy"
                    src={media.localFile?.publicURL}
                    alt={alt || ''}
                  />
                </picture>
              </div>
            )
          : null }
        {youtube_id && (
          <LiteYoutubeEmbed
            id={youtube_id}
            mute={false}
            desktopResolution="maxresdefault"
            iframeTitle="YouTube Embed"
            noCookie
            lazyImage
            isMobile
          />
        )}
        {embed_url && !youtube_id && (
          <iframe
            src={embed_url}
            title={title}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            width={width}
            height={height}
          />
        )}
        {gif?.url && (
          <img
            src={gif.url}
            alt={alt || ''}
            width={width}
            height={height}
          />
        )}
        {recording && (
          <audio controls autoPlay src={recording} className="recording" style={{ width: `${width}px`, height: `${height}px`, margin: 0 }} />
        )}
      </div>
    </section>
  );
}
export default EmbeddedMedia;
