import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import isLocalFileImage from '../../utils/isLocalFileImage';

export default function PrismicImage({ image, alt, mobileMedia}) {
  return (
    <div>
      {isLocalFileImage(image) ? (
        <>
          <GatsbyImage
            className="image desktop"
            image={getImage(image.localFile)}
            alt={alt || ''}
            loading="eager"
            objectFit="unset"
          />
          {mobileMedia ? (
            <GatsbyImage
              className="image mobile"
              image={getImage(mobileMedia.localFile)}
              alt={alt || ''}
              loading="eager"
              objectFit="cover"
              objectPosition="0% 90%"
            />
          )
            : null }
        </>
      ) : (
        <div
          style={{ backgroundImage: `url("${image.url}")` }}
          className="image preview"
        />
      )}
    </div>

  );
}
