import React, { useState, useEffect } from 'react';
import './IdeasReactorForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { asText } from '@prismicio/helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';

const IdeasReactorForm = ({ slice, metaTitle }) => {
  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm();
  const {
    portal_id,
    form_id,
    google_conversion_name,
  } = slice.primary;

  const [captchaValue, setCaptchaValue] = useState("");
  const [notVerified, setNotVerified] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [updateState, setUpdateState] = useState([]);
  const firstNameRegister = register('firstname', { required: true });
  const lastNameRegister = register('lastname', { required: true });
  const companyNameRegister = register('company', { required: true });
  const emailRegister = register('email', { required: true });
  
  const consent = {
    consent: {
      consentToProcess: true,
      text: 'I agree to allow CloudArmy to store and process my personal data.',
      communications: [
        {
          value: true,
          subscriptionTypeId: 999,
          text: 'I agree to receive marketing communications from CloudArmy.',
        },
      ],
    },
  };

  const onReCAPTCHA = (value) => {
    if (value) {
      setNotVerified(false);
    }
    setCaptchaValue(value);
  }

  const [formData, setFormData] = useState({
    fields: [
      {
        name: 'TICKET.idea_1',
        value: '',
      },
      {
        name: 'TICKET.idea_2',
        value: '',
      },
      {
        name: 'TICKET.idea_3',
        value: '',
      },
      {
        name: 'TICKET.idea_4',
        value: '',
      },
      {
        name: 'TICKET.idea_5',
        value: '',
      },
      {
        name: 'TICKET.idea_6',
        value: '',
      },
      {
        name: 'TICKET.idea_7',
        value: '',
      },
      {
        name: 'TICKET.idea_8',
        value: '',
      },
      {
        name: 'TICKET.idea_9',
        value: '',
      },
      {
        name: 'TICKET.idea_10',
        value: '',
      },
      {
        name: 'firstname',
        value: '',
      },
      {
        name: 'lastname',
        value: '',
      },
      {
        name: 'company',
        value: '',
      },
      {
        name: 'email',
        value: '',
      },
      {
        name: 'phone',
        value: '',
      },
      {
        name: 'TICKET.additional_information',
        value: '',
      },
      {
        name: 'TICKET.refuse_standard_attributes',
        value: false,
      },
      {
        name: 'TICKET.introductory_text',
        value: '',
      },
      {
        name: 'TICKET.product_service_category',
        value: '',
      },
      {
        name: 'TICKET.field_country',
        value: '',
      },
      {
        name: 'TICKET.sample_criteria',
        value: '',
      },
    ],
  });

  const [selectedCategories, setSelectedCategories] = useState([]);
  const categories = ['Product Issue', 'Billing Issue', 'Feature Request', 'General Inquiry'];

  const setData = (key, value) => {
    const objIndex = formData.fields.findIndex(((obj) => obj.name === key));
    formData.fields[objIndex].value = value;
    let newState = [...formData.fields];
    setUpdateState(newState);
  };

  const handleHubspotSubmit = (event) => {
    if (captchaValue?.length) {
      const timestamp = new Date();
      const xhr = new XMLHttpRequest();
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portal_id}/${form_id}`;

      formData.legalConsentOptions = consent;

      const finalData = JSON.stringify(formData);
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setSentMessage(true); // success
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(xhr.responseText); // Returns a 404 error if the formGuid isn't found
        }
      };
      xhr.send(finalData);
      window.dataLayer.push({
        'event': google_conversion_name,
        'hs-form-guid': form_id,
      });
    } else {
      setNotVerified(true);
    }
  };

  return (
    <form className="ideas-reactor-container" onSubmit={handleSubmit(handleHubspotSubmit)}>
      {sentMessage ? 
      <div className="sent">Thank you for choosing CloudArmy's Idea Reactor.</div> 
      : 
      <div className="not-sent" style={{display: 'flex'}} id="not-sent">
        <div className="ideas-container">
          <div className="idea-container-1">
            <div className="idea-label-1" id="label">Idea 1</div>
            <input 
              className="idea-input-1 textline"
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_1', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-2">
            <div className="idea-label-2" id="label">Idea 2</div>
            <input 
              className="idea-input-2 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_2', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-3">
            <div className="idea-label-3" id="label">Idea 3</div>
            <input 
              className="idea-input-3 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_3', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-4">
            <div className="idea-label-4" id="label">Idea 4</div>
            <input 
              className="idea-input-4 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_4', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-5">
            <div className="idea-label-5" id="label">Idea 5</div>
            <input 
              className="idea-input-5 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_5', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-6">
            <div className="idea-label-6" id="label">Idea 6</div>
            <input 
              className="idea-input-6 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_6', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-7">
            <div className="idea-label-7" id="label">Idea 7</div>
            <input 
              className="idea-input-7 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_7', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-8">
            <div className="idea-label-8" id="label">Idea 8</div>
            <input 
              className="idea-input-8 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_8', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-9">
            <div className="idea-label-9" id="label">Idea 9</div>
            <input 
              className="idea-input-9 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_9', e.target.value)
              }}
            />
          </div>
          <div className="idea-container-10">
            <div className="idea-label-10" id="label">Idea 10</div>
            <input 
              className="idea-input-10 textline" 
              type="text"
              placeholder=""
              maxLength={170}
              onChange={(e) => {
                setData('TICKET.idea_10', e.target.value)
              }}
            />
          </div>
        </div>
        <div className="ideas-reactor-standard-attributes-input">
          <input type="checkbox" onChange={(e) => {
            setData('TICKET.refuse_standard_attributes', e.target.checked);
          }}/>
          <div className="standard-attributes-label">I don't want to use the standard attributes (please contact me to discuss why).</div>
        </div>
        <div className="ideas-reactor-introductory-text-input">
          <div className="introductory-text-label" id="label">Introductory Text. Please explain the context of these ideas in 3 sentences or less, using consumer-friendly language. (Respondents will see this text before the statements they evaluate.)</div>
          <textarea
            className="introductory-text textarea"
            id="introductory-text"
            type="text"
            placeholder=""
            onChange={(e) => {
              setData('TICKET.introductory_text', e.target.value)
            }}
          />
        </div>
        <div className="country-and-product-input">
          <div className="ideas-reactor-field-country-input">
            <div className="field-country-label" id="label">Field Country</div>
            <input
              className="field-country textline"
              id="field-country"
              type="text"
              placeholder=""
              onChange={(e) => {
                setData('TICKET.field_country', e.target.value)
              }}
            />
          </div>
          <div className="ideas-reactor-product-input">
            <div className="product-label" id="label">Product / Service Category</div>
            <input
              className="product textline"
              id="product"
              type="text"
              placeholder=""
              onChange={(e) => {
                setData('TICKET.product_service_category', e.target.value)
              }}
            />
          </div>
        </div>
        <div className="ideas-reactor-sample-criteria-input">
          <div className="sample-criteria-label" id="label">Sample Criteria (Please share details on who should take part in this study)</div>
          <textarea
            className="sample-criteria textarea"
            id="sample-criteria"
            type="text"
            placeholder=""
            onChange={(e) => {
              setData('TICKET.sample_criteria', e.target.value)
            }}
          />
        </div>
        <div className="name-container">
          <div className="ideas-reactor-first-name-input">
            <div className="first-name-label" id="label">First Name *</div>
            <input
              className={`first-name ${errors.firstname?.type} textline`}
              id="first-name"
              type="text"
              placeholder=""
              {...firstNameRegister}
              onChange={(e) => {
                firstNameRegister.onChange(e);
                setData('firstname', e.target.value)
              }}
            />
          </div>
          <div className="ideas-reactor-last-name-input">
            <div className="last-name-label" id="label">Last Name *</div>
            <input
              className={`last-name ${errors.lastname?.type} textline`}
              id="last-name"
              type="text"
              placeholder=""
              {...lastNameRegister}
              onChange={(e) => {
                lastNameRegister.onChange(e);
                setData('lastname', e.target.value)
              }}
            />
          </div>
          <div className="ideas-reactor-company-name-input">
            <div className="company-label" id="label">Company Name *</div>
            <input
              className={`company ${errors.company?.type} textline`}
              id="company"
              type="text"
              placeholder=""
              {...companyNameRegister}
              onChange={(e) => {
                companyNameRegister.onChange(e);
                setData('company', e.target.value)
              }}
            />
          </div>
        </div>
        <div className="email-and-phone-container">
          <div className="ideas-reactor-email-input">
            <div className="email-label" id="label">Email *</div>
            <input
              className={`email ${errors.email?.type} textline`}
              type="text"
              placeholder=""
              {...emailRegister}
              onChange={(e) => {
                emailRegister.onChange(e)
                setData('email', e.target.value)
              }}
            />
          </div>
          <div className="ideas-reactor-mobile-phone-input">
            <div className="mobile-phone-label" id="label">Phone Number</div>
            <input
              className={`mobile-phone ${errors.phone?.type} textline`}
              type="text"
              placeholder=""
              onChange={(e) => {
                setData('phone', e.target.value)
              }}
            />
          </div>
        </div>
        <div className="ideas-reactor-additional-information-input">
          <div className="additional-information-label" id="label">Additional Information</div>
          <textarea
            className="additional-information textarea"
            id="additional-information"
            type="text"
            placeholder=""
            onChange={(e) => {
              setData('TICKET.additional_information', e.target.value)
            }}
          />
        </div>
        <div className="button-wrapper">
          <div className={`captcha ${notVerified ? 'not-verified' : 'verified'}`}>
            <ReCAPTCHA
              sitekey="6LfsCP8fAAAAANoUgdFHzLdc7CxAUkPLd-lfPA77"
              onChange={onReCAPTCHA}
              className="captcha"
            />
          </div>
          <input
            className="submit"
            type="submit"
            value="SUBMIT"
          />
          </div>
      </div>
      }
    </form>
  );
};

export default IdeasReactorForm;
