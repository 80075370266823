import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './FullWidthImageWithTextOnImage.scss';

const FullWidthImageWithTextOnImage = ({ slice, blogInfo, type }) => {
  const {
    title,
    title_color,
    description,
    description_color,
    image,
  } = slice.primary;

  const fullWidthImage = getImage(image.localFile);
  return (
    <section className="full-width-image-with-text-on-image">
      <div className="image-container">
        <GatsbyImage
          className="image"
          image={fullWidthImage}
          alt={image.alt || ''}
          loading="eager"
          objectFit="cover"
        />
      </div>
      <div className="text-container">
        <div className="title" style={{ color: title_color }}>{asText(title.raw)}</div>
        <div className="description" style={{ color: description_color }}>{asText(description.raw)}</div>
      </div>
    </section>
  );
};

export default FullWidthImageWithTextOnImage;
