import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import './Grid.scss';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';

const Grid = ({ slice }) => (
  <section className="grid-container">
    <div className="title">{asText(slice.primary.title.raw)}</div>
    <div className="items">
      {slice.items.map((item, index) => {
        const image = getImage(item.image.localFile);
        return (
          <div className="item" key={index}>
            {image ? (
              <GatsbyImage
                image={image}
                className="image"
                alt={item.image.alt || ''}
              />
            )
              : (
                <img
                  className="gif"
                  src={item.gif.url}
                  alt={item.gif.alt || ''}
                />
              )}
            <div className={`text-container ${item.text_position}`}>
              <Link to={item.link.document?.url || item.link?.url}>
                {item.author.text.length ?
                <div className="author">{item.author.text}</div>
                : null}
                <div className="title">{item.title.text}</div>
              </Link>
              <Link to={item.link.document?.url || item.link?.url}>
                {item.method !== 'none' && <div className="button">{item.method}</div>}
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  </section>
);

export default Grid;
