import React, { useState } from 'react';
import './Tags.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import FacebookSVG from '../../images/icons/facebook-gray.svg';
import TwitterSVG from '../../images/icons/twitter-gray.svg';
import LinkedInSVG from '../../images/icons/linkedin-gray.svg';
import EmailSVG from '../../images/icons/email-gray.svg';
import CopySVG from '../../images/icons/copy-gray.svg';

const Tags = ({
  tags, url, podcast, desktop, alignment,
}) => {
  const [showSocials, setShowSocials] = useState(false);
  const [copied, setCopied] = useState(false);
  const showSocialsDiv = () => setShowSocials(!showSocials);
  const openWindow = (type) => {
    let provider;
    const baseURL = podcast ? '' : 'https://cloud.army';
    if (type === 'facebook') {
      provider = `https://www.facebook.com/sharer/sharer.php?u=${baseURL}`;
    } else if (type === 'twitter') {
      provider = `http://www.twitter.com/share?url=${baseURL}`;
    } else if (type === 'linkedin') {
      provider = `https://www.linkedin.com/sharing/share-offsite/?url=${baseURL}`;
    }
    if (type === 'copy') {
      navigator.clipboard.writeText(baseURL + url);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } else {
      window.open(`${provider}${url}`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }
  };

  let filteredTags;
  if (tags) {
    filteredTags = tags;
    filteredTags = filteredTags.filter((e) => e !== 'blog post' && e !== 'resources');
  }

  return (
    <section className={`tags-wrapper ${desktop ? 'share-desktop' : 'share-mobile'}`}>
      {tags && (
        <div className="tags">
          {' '}
          TAGS:&nbsp;
          {filteredTags.map((item, index) => (
            <div className="tag" key={index}>
              #
              {item.toUpperCase()}
            &nbsp;
            </div>
          ))}
        </div>
      )}
      <div className={`clickables ${alignment || 'left'}`}>
        {showSocials && (
          <div className="socials">
            <button type="button" onClick={() => openWindow('facebook')}>
              <img className="facebook" src={FacebookSVG} alt="facebook" />
            </button>
            <button type="button" onClick={() => openWindow('twitter')}>
              <img className="twitter" src={TwitterSVG} alt="twitter" />
            </button>
            <button type="button" onClick={() => openWindow('linkedin')}>
              <img className="linkedin" src={LinkedInSVG} alt="linkedin" />
            </button>
            <a href={`mailto:?subject=Check out this Blog&body=https://cloud.army${url}`}>
              <img className="email" src={EmailSVG} alt="email" />
            </a>
            <button type="button" onClick={() => openWindow('copy')}>
              <img className="copy" src={CopySVG} alt="copy" />
            </button>
          </div>
        )}
        <div
          className="share-wrapper"
          onClick={() => showSocialsDiv()}
        >
          <StaticImage
            src="../../images/icons/share.png"
            alt="share"
            className="share"
            objectFit="none"

          />
        </div>
      </div>
      {copied && <div className="copied">Link Copied Successfully!</div>}
    </section>
  );
};
export default Tags;
