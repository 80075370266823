import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { asText } from '@prismicio/helpers';
import './TextInfoWithSideImage.scss';

const TextInfoWithSideImage = ({ slice, type, metaTitle }) => {
  const {
    title,
    title_color,
    description,
    description_color,
    image,
    image_position,
  } = slice.primary;
  console.log(image);
  const imageData = getImage(image.localFile);
  function createMarkup() {
    const output = description_html && description_html.text ? description_html.text.replace(/(?:&nbsp;)(?:\\[rn]|[\r\n])/g, '<br/><br/>') : '';
    return { __html: output };
  }

  const addBreaks = (raw) => {
    return raw.map((r) => {
      if (!r.text.length) {
        r.text = '\n';
      }
      return r;
    })
  }

  return (
    <section className="text-info-with-side-image-container">
      <div className="image-container">
        <GatsbyImage
          className="image mobile"
          image={imageData}
          alt={image.alt || ''}
          loading="eager"
          objectFit="cover"
        />
      </div>
      <div className="text-container">
        <div className="title" style={{ color: title_color }}>
          <PrismicRichText field={addBreaks(title?.richText) || []} />
        </div>
        <div className="description" style={{ color: description_color }}>
          <PrismicRichText field={addBreaks(description?.richText) || []} />
        </div>
      </div>
    </section>
  );
};

export default TextInfoWithSideImage;
