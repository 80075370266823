import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import './CircleFlow.scss';

const CircleFlow = ({ slice }) => {
  const {
    title,
  } = slice.primary;

  return (
    <div className="circle-flow-container">
      <div className="circle-flow-container-title">
        {asText(title.raw)}
      </div>
      <div className="circle-flow">
        {slice.items.map((item, index) => {
          const {
            flow_title, flow_description, icon,
          } = item;
          const prismicImage = getImage(icon.localFile);
          return (
            <div className="circle-flow-wrapper" key={index}>
              <div className="circle-flow-icon">
                <GatsbyImage
                  image={prismicImage}
                  alt={prismicImage.alt || ''}
                  loading="eager"
                  objectFit="contain"
                />
                <div className="circle-flow-arrow-icon">
                  <StaticImage
                    className="arrow"
                    src="../../../images/icons/white-arrow-right.png"
                    alt="dropdown"
                    objectFit="contain"
                  />
                </div>
              </div>
              <div className="circle-flow-text-container">
                <div className="circle-flow-text-container-title">
                  {asText(flow_title.raw)}
                </div>
                <div className="circle-flow-text-container-description">
                  {asText(flow_description.raw)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CircleFlow;
