import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import './Profile.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProcessFlow = ({ slice }) => {
  const {
    name,
    title,
    description,
    image,
  } = slice.primary;
  const userImage = getImage(image.localFile);

  return (
    <section className="profile">
      <div className="image">
        <GatsbyImage image={userImage} alt={image.alt} />
      </div>
      <div className="text-container">
        <div className="person">
          <div className="name">
            <PrismicRichText field={name.raw} />
          </div>
          <div className="title">
            <PrismicRichText field={title.raw} />
          </div>
        </div>
        <div className="description">
          <PrismicRichText field={description.raw} />
        </div>
      </div>
    </section>
  );
};

export default ProcessFlow;
