import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import './TextWithDivider.scss';

const TextWithDivider = ({ slice }) => {
  const {
    email,
    phone_number,
  } = slice.primary;
  return (
    <section className="text-with-divider">
      <div className="first-row">
        <div className="email">{asText(email.raw).toUpperCase()}</div>
        <div className="phone-number">{asText(phone_number.raw)}</div>
      </div>
      <div className="second-row">
        <div className="locations">
          {slice.items.map((item, index) => (
            <div key={index}>{asText(item.location.raw).toUpperCase()}</div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TextWithDivider;
