import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './AlternatingFeatures.scss';

const AlternatingTextAndImageBlocks = ({ slice }) => {
  const {
    title,
  } = slice.primary;

  return (
    <section className="alternating-features-container">
      <div className="alternating-features-title">
        {asText(title.raw)}
      </div>
      {slice.items.map((item, index) => {
        const {
          feature_title, feature_description, image,
        } = item;
        const prismicImage = getImage(image.localFile);
        return (
          <div className={`alternating-feature ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
            <div className="alternating-feature-text-container">
              <div className={`alternating-feature-index ${index}`}>
                {index < 10 ? '0' + (index + 1) : index + 1}
              </div>
              <div className="alternating-feature-title">
                {asText(feature_title.raw)}
              </div>
              <div className="alternating-feature-description">
                {asText(feature_description.raw)}
              </div>
            </div>
            <div className="alternating-feature-image">
              <GatsbyImage
                image={prismicImage}
                alt={prismicImage.alt || ''}
                loading="eager"
                objectFit="contain"
              />
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default AlternatingTextAndImageBlocks;
