import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Stream from './Stream';
import PrismicImage from '../../PrismicImage/PrismicImage';
import './_full-width-media.scss';

const FullWidthMedia = ({ slice, blogInfo, type }) => {
  const {
    description,
    media,
    mobile_media,
    title,
    text_position,
    title_font_size,
    media_above_background,
    other_ways_to_listen,
    description_position,
    description_color,
    title_position,
    title_color,
    mini_title,
  } = slice.primary;

  let aboveBackground;
  if (media_above_background) {
    aboveBackground = getImage(slice.primary.media_above_background.localFile);
  }
  let formattedDate;
  if (blogInfo) {
    let date = new Date(blogInfo.date);
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  const isBlogOrArticle = type === 'blog_post' || type === 'knowledge_center_article';

  function createMarkup() {
    const output = description && description.text ? description.text.replace(/(?:&nbsp;)(?:\\[rn]|[\r\n])/g, '<br/><br/>') : '';
    return { __html: output };
  }

  return (
    <section className={`full-width-media auto-grid ${text_position} ${other_ways_to_listen ? 'margin-bottom' : ''}`}>
      <PrismicImage image={media} mobileMedia={mobile_media || null} alt={slice.primary.alt} />
      {media_above_background && aboveBackground && (
        <div className="above-background-image">
          <GatsbyImage
            image={aboveBackground}
            alt={aboveBackground.alt || ''}
          />
        </div>
      )}
      {title && (
        <div className={`text-container ${text_position} ${description.text ? '' : 'only-title'} ${title_position} ${isBlogOrArticle ? 'blog' : 'normal'}`}>
          <div className={`title ${title_font_size} ${title_color}`}>
            <PrismicRichText field={title.raw || []} />
          </div>
          <div className="mini-title">
            <PrismicRichText field={mini_title?.raw || []} />
          </div>
          {description.text.length > 0 && <div className={`description ${description_position} ${description_color}`}>
            <div className="inner-html" dangerouslySetInnerHTML={createMarkup()} />
          </div>
          }
          {slice.items && slice.items[0]?.location?.text?.length > 0 && slice.items.length > 0 && (
            <div className="location-wrapper">
              {slice.items.map((item, index) => (
                <div className="location" key={index}>
                  {item.location && asText(item.location.raw).toUpperCase()}
                </div>
              ))}
            </div>
          )}
          {slice.items && slice.items.length && (
            <div className="button-wrapper">
              {slice.items.map((item, index) => {
                const element = item.button_fragment?.text && typeof window !== 'undefined' ? document.getElementById(item.button_fragment.text) : null;
                return (
                  <div key={index}>
                    {item.button ?
                    <a onClick={() => element.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                      <div className="button" style={{color: item.button_text_color, backgroundColor: item.button_color}} key={index}>
                        {asText(item.button_text.raw)}
                      </div>
                    </a>
                    : null
                    }
                </div>
                )}
              )}
            </div>
            )
          }
          {other_ways_to_listen && (
            <Stream />
          )}
          {blogInfo?.author && (
            <div className="author">
              BY:
              {' '}
              {blogInfo.author.toUpperCase()}
              {blogInfo?.author_title && `, ${blogInfo.author_title.toUpperCase()}`}
            </div>
          )}
          {blogInfo?.date && (
            <div className="date">
              Published on:
              {' '}
              {formattedDate}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default FullWidthMedia;
