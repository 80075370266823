module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeCachingHeaders":true,"headers":{"/static/*/*/*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/987-*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/231-*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/175-*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/app-*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/framework-*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/icons/*":["Cache-Control: public,max-age=604800"],"/favicon-32x32.png":["Cache-Control: public,max-age=604800"],"*.png":["Cache-Control: public,max-age=604800,s-maxage=604800,immutable"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/cloudarmylogo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3b1618fd912d1e3654a5a3c31eddca5f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"cloudarmy-website","accessToken":"MC5ZUGRla1JJQUFDZ0FEOGJI.aO-_ve-_vRVEA--_vRXvv73vv71y77-977-977-9PO-_vQEHZO-_vQTvv70IMnDvv71577-9I--_vTfvv70","promptForAccessToken":true,"apiEndpoint":"https://cloudarmy-website.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RQP7B3WV1L","AW-10850095535"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TPTN9XV","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
