import React, { Suspense, lazy } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import './MediaDescription.scss';

const MediaDescription = ({ slice }) => {
  const {
    media,
    title,
    title_color,
    description,
    description_color,
    youtube_id,
    embed_url,
    width,
    height,
  } = slice.primary;
  // use when there's an instance of image
  // const image = getImage(media.localFile);

  return (
    <section className="media-description">
      <div className="media-with-description">
        {youtube_id && (
          <LiteYoutubeEmbed
            id={youtube_id}
            mute={false}
            desktopResolution="maxresdefault"
            iframeTitle="YouTube Embed"
            noCookie
            lazyImage
            isMobile
          />
        )}
        {embed_url && !youtube_id && (
          <iframe
            src={embed_url}
            title={title}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            width={width}
            height={height}
          />
        )}
      </div>
      <div className="title-and-description">
        <div className={`title ${title_color}`}>
          <PrismicRichText field={title.raw || []} />
        </div>
        <div className={`description ${description_color}`}>
          <PrismicRichText field={description.raw || []} />
        </div>
      </div>
    </section>
  );
};

export default MediaDescription;
