import React, { useState, useEffect } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './ContactUs.scss';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { DebounceInput } from 'react-debounce-input';

const ContactUs = ({ slice }) => {
  const {
    title,
    title_color,
    description,
    description_color,
    description_position,
    has_tag_filter,
  } = slice.primary;
  const [employees, setEmployees] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState(null);
  const [searchByText, setSearchByText] = useState('');
  const [searchByTextList, setSearchByTextList] = useState([]);
  const [searchByTag, setSearchByTag] = useState('');
  const [searchByTagList, setSearchByTagList] = useState([]);

  const data = useStaticQuery(graphql`
    query MyQuery {
      allPrismicPage {
        nodes {
          data {
            body {
              ... on PrismicPageDataBodyContactUs {
                id
                slice_type
                slice_label
                primary {
                  description {
                    text
                    html
                    raw
                    richText
                  }
                  title {
                    html
                    raw
                    richText
                    text
                  }
                  has_tag_filter
                }
                items {
                  employee_name {
                    html
                    raw
                    richText
                    text
                  }
                  employee_tag {
                    html
                    raw
                    richText
                    text
                  }
                  job_title {
                    html
                    raw
                    richText
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    url
                  }
                  link {
                    document {
                      ... on PrismicPage {
                        id
                        url
                      }
                    }
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const handleSearch = (text, list) => {
    const regexText = new RegExp(text.toUpperCase());
    const result = list.filter((employee) => {
      const {
        employee_name,
        job_title,
      } = employee;
      return regexText.test(employee_name.text.toUpperCase())
        || regexText.test(job_title.text.toUpperCase());
    })
    setFilteredEmployees(result);
    setSearchByTextList(result);
  };

  const handleTag = (tag, list) => {
    const regexText = new RegExp(tag.toUpperCase());
    const result = list.filter((employee) => {
      const {
        employee_tag,
      } = employee;
      return regexText.test(employee_tag.text.toUpperCase());
    })
    setFilteredEmployees(result);
    setSearchByTagList(result);
  }

  const handleSearchPosts = () => {
    if (searchByTag.length > 0) {
      handleSearch(searchByText, searchByTagList);
    } else if (employees) {
      handleSearch(searchByText, employees);
    }
  };

  const handleTagChange = () => {
    if (searchByText.length > 0) {
      handleTag(searchByTag, searchByTextList);
    } else if (employees) {
      handleTag(searchByTag, employees);
    }
  };

  const getEmployeesWithImages = (dataObject) => {
    const gatsbyImageDataArray = [];
    if (
      dataObject &&
      Array.isArray(dataObject)
    ) {
      dataObject.forEach((item) => {
        if (
          item?.data?.body.length > 0
        ) {
          item.data.body.forEach((emp) => {
            if (emp.items) {
              emp.items.forEach((i) => {
                if (i.image.localFile) {
                  gatsbyImageDataArray.push(item);
                }
              })
            }
          })
        }
      });
    }
    return gatsbyImageDataArray[0].data.body;
  }

  useEffect(() => {
    const employeeData = getEmployeesWithImages(data.allPrismicPage.nodes);
    const joinAllEmployees = [{ items: [].concat(...employeeData.map(obj => obj.items)) }];
    setEmployees(joinAllEmployees[0].items);
  }, []);

  useEffect(handleSearchPosts, [searchByText]);
  useEffect(handleTagChange, [searchByTag]);

  useEffect(() => {
    let element = document.getElementById('advisors');
    if (searchByText.length > 0 || searchByTag.length > 0) {
      element.classList.add('hide');
      element.classList.remove('show');
    } else {
      element.classList.add('show');
      element.classList.remove('hide');
    }
  }, [searchByText, searchByTag]);

  const filtered = searchByText.length > 0 || searchByTag.length > 0 ? filteredEmployees : slice.items; 
  const ifNoImage = (item) => {
    if (item.length) {
      return (
        <img
          src={item.image.localFile?.publicURL}
          alt={item.image.alt}
        />
      )
    } else {
      return (
        <div className="logo" />
      )
    }
  };

  const onTagClick = (tag) => {
    setSearchByTag(tag);
    if (searchByTag === tag) {
      setSearchByTag('');
    }
  }

  const isAdvisors = title.text === 'Advisors and Associates';
  return (
    <section className="contact-us" id={`${isAdvisors ? 'advisors' : ''}`}>
      <div className="container">
        <div className="title" style={{color: title_color || '#1E1069'}}>
          <PrismicRichText field={title.raw || []} />
        </div>
        <div className="description" style={{color: description_color || '#1E1069', textAlign: description_position || 'start'}}>
          <PrismicRichText field={description.raw || []} />
        </div>
        <div className="filter-container">
          {has_tag_filter ? <div className="filter-and-search-container">
            <DebounceInput
              className="search"
              minLength={1}
              debounceTimeout={500}
              onChange={(event) => setSearchByText(event.target.value)}
              placeholder="SEARCH"
            />
          </div> : null}
          {has_tag_filter ? 
            <div className="filter-tags">
              <div className="tag" style={{color: searchByTag.includes('Consumer Neuroscience') ? 'yellow' : 'white'}} onClick={(e) => onTagClick('Consumer Neuroscience')}>Consumer Neuroscience</div>
              <div className="tag" style={{color: searchByTag.includes('Software Development') ? 'yellow' : 'white'}} onClick={(e) => onTagClick('Software Development')}>Software Development</div>
              <div className="tag" style={{color: searchByTag.includes('Sales and Marketing') ? 'yellow' : 'white'}} onClick={(e) => onTagClick('Sales and Marketing')}>Sales and Marketing</div>
              <div className="tag" style={{color: searchByTag.includes('Finance') ? 'yellow' : 'white'}} onClick={(e) => onTagClick('Finance')}>Finance</div>
              <div className="tag" style={{color: searchByTag.includes('Leadership') ? 'yellow' : 'white'}} onClick={(e) => onTagClick('Leadership')}>Leadership</div>
              <div className="tag" style={{color: searchByTag.includes('Advisors and Associates') ? 'yellow' : 'white'}} onClick={(e) => onTagClick('Advisors and Associates')}>Advisors and Associates</div>
            </div> 
            : null
          }
        </div>
        <div className="images-container">
          {filtered?.length ? filtered.map((item, index) => {
            const image = getImage(item.image.localFile);
            const employeeTags = item.employee_tag.text;
            return (
              <div className="images" key={index}>
                <Link
                  className="image-link-wrapper"
                  to={item.link?.document?.url || undefined}
                >
                  {item.image.localFile?.childImageSharp ?
                    <GatsbyImage
                      className="logo"
                      image={image}
                      alt={item.image.alt || ''}
                      loading="eager"
                    /> :
                    ifNoImage(item)
                  }
                </Link>
                <div className="person">
                  <Link to={item.link?.document?.url || undefined}>
                    <div className="name">{asText(item.employee_name.raw).toUpperCase()}</div>
                    <div className="position">{asText(item.job_title.raw).toUpperCase()}</div>
                  </Link>
                </div>
              </div>
            );
          }) : <div className="no-results">There are no results. Please re-do your search.</div>}
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
