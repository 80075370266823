import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import HubspotForm from 'react-hubspot-form';
import ReCAPTCHA from 'react-google-recaptcha';

const NudgestockForm = ({ consent, onSend, slice, why_are_you_contacting_us }) => {
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm();

  const [captchaValue, setCaptchaValue] = useState("");
  const [notVerified, setNotVerified] = useState(false);

  const [formData, setFormData] = useState({
    fields: [
      {
        name: 'firstname',
        value: '',
      },
      {
        name: 'lastname',
        value: '',
      },
      {
        name: 'company',
        value: '',
      },
      {
        name: 'country',
        value: '',
      },
      {
        name: 'email',
        value: '',
      },
      {
        name: 'phone',
        value: '',
      },
    ],
  });

  const handleHubspotSubmit = (event) => {
    if (captchaValue?.length) {
      const timestamp = new Date();
      const milliseconds = timestamp.getTime();
  
      const xhr = new XMLHttpRequest();
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${slice.primary.portal_id}/${slice.primary.form_id}`;
  
      formData.submittedAt = milliseconds.toString();
      formData.legalConsentOptions = consent;
  
      const finalData = JSON.stringify(formData);
  
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
  
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
         onSend(); // success
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(xhr.responseText); // Returns a 404 error if the formGuid isn't found
        }
      };
      xhr.send(finalData);
    } else {
      setNotVerified(true);
    }
  };

  const setData = (key, value) => {
    const objIndex = formData.fields.findIndex(((obj) => obj.name === key));
    formData.fields[objIndex].value = value;
  };

  const firstNameRegister = register('firstname', { required: true });
  const lastNameRegister = register('lastname', { required: true });
  const companyRegister = register('company', { required: true });
  const countryRegister = register('country', { required: true });
  const emailRegister = register('email', { required: true });
  const phoneRegister = register('phone', { required: true });

  const onReCAPTCHA = (value) => {
    if (value) {
      setNotVerified(false);
    }
    setCaptchaValue(value);
  }

  return (
    <>
      <form className="form" onSubmit={handleSubmit(handleHubspotSubmit)}>
        <div className="expanded-form">
          <div className="first-row">
            {/* <label for="first-name error">*required</label> */}
            <input
              className={`first-name ${errors.firstname?.type}`}
              type="text"
              placeholder="FIRST NAME"
              {...firstNameRegister}
              onChange={(e) => {
                firstNameRegister.onChange(e);
                setData('firstname', e.target.value)
              }}
            />
            <input
              className={`last-name ${errors.lastname?.type}`}
              type="text"
              placeholder="LAST NAME"
              {...lastNameRegister}
              onChange={(e) => {
                lastNameRegister.onChange(e);
                setData('lastname', e.target.value)
              }}
            />
          </div>
          <div className="second-row">
            <input
              className={`company ${errors.company?.type}`}
              type="text"
              placeholder="COMPANY"
              {...companyRegister}
              onChange={(e) => {
                companyRegister.onChange(e)
                setData('company', e.target.value)
              }}
            />
            <input
              className={`country ${errors.country?.type}`}
              type="text"
              placeholder="COUNTRY"
              {...countryRegister}
              onChange={(e) => {
                countryRegister.onChange(e)
                setData('country', e.target.value)
              }}
            />
          </div>
          <div className="third-row">
            <input
              className={`email ${errors.email?.type}`}
              type="text"
              placeholder="EMAIL"
              {...emailRegister}
              onChange={(e) => {
                emailRegister.onChange(e)
                setData('email', e.target.value)
              }}
            />
            <input
              className={`phone ${errors.phone?.type}`}
              type="text"
              placeholder="PHONE"
              {...phoneRegister}
              onChange={(e) => {
                phoneRegister.onChange(e);
                setData('phone', e.target.value)
              }}
            />
          </div>
        </div>
        <div className="button-wrapper">
          <input
            className="submit"
            type="submit"
            value="SUBMIT"
          />
          <span className="hover-circle normal" />
          <div className={`captcha ${notVerified ? 'not-verified' : 'verified'}`}>
            <ReCAPTCHA 
              sitekey="6LfsCP8fAAAAANoUgdFHzLdc7CxAUkPLd-lfPA77"
              onChange={onReCAPTCHA}
              className="captcha"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default NudgestockForm;