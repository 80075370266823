import React, { useState, useEffect } from 'react';
import './BrandTrackForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { asText } from '@prismicio/helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import { PrismicRichText } from '@prismicio/react';

const BrandTrackForm = ({ slice, metaTitle }) => {
  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm();
  const {
    title,
    description,
    submit_text,
    portal_id,
    form_id,
    google_conversion_name,
    terms_and_conditions_text,
  } = slice.primary;

  const [captchaValue, setCaptchaValue] = useState("");
  const [notVerified, setNotVerified] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [updateState, setUpdateState] = useState([]);
  const firstNameRegister = register('firstname', { required: false });
  const lastNameRegister = register('lastname', { required: false });
  const companyNameRegister = register('company', { required: false });
  const titleRegister = register('title', { required: false });
  const emailRegister = register('email', { required: true });

  const consent = {
    consent: {
      consentToProcess: true,
      text: 'I agree to allow CloudArmy to store and process my personal data.',
      communications: [
        {
          value: true,
          subscriptionTypeId: 999,
          text: 'I agree to receive marketing communications from CloudArmy.',
        },
      ],
    },
  };

  const onReCAPTCHA = (value) => {
    if (value) {
      setNotVerified(false);
    }
    setCaptchaValue(value);
  }
  const [formData, setFormData] = useState({
    fields: [
      {
        name: 'firstname',
        value: '',
      },
      {
        name: 'lastname',
        value: '',
      },
      {
        name: 'company',
        value: '',
      },
      {
        name: 'email',
        value: '',
      },
      {
        name: 'title',
        value: '',
      },
    ],
  });

  const setData = (key, value) => {
    const objIndex = formData.fields.findIndex(((obj) => obj.name === key));
    formData.fields[objIndex].value = value;
    let newState = [...formData.fields];
    setUpdateState(newState);
  };

  const handleHubspotSubmit = (event) => {
    if (captchaValue?.length) {
      const timestamp = new Date();

      const xhr = new XMLHttpRequest();
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portal_id}/${form_id}`;

      formData.legalConsentOptions = consent;

      const finalData = JSON.stringify(formData);
      
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setSentMessage(true); // success
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(xhr.responseText); // Returns a 404 error if the formGuid isn't found
        }
      };
      xhr.send(finalData);
      window.dataLayer.push({
        'event': google_conversion_name,
        'hs-form-guid': form_id,
      });
    } else {
      setNotVerified(true);
    }
  };

  const addBreaks = (raw) => {
    return raw.map((r) => {
      if (!r.text.length) {
        r.text = '\n';
      }
      return r;
    })
  }

  return (
    <form className="brand-track-container" onSubmit={handleSubmit(handleHubspotSubmit)}>
      {sentMessage ? 
      <div className="sent">${submit_text.length ? <PrismicRichText field={submit_text.raw || []} /> : 'Thank you for subscribing to Brand Tracking.'}</div> 
      : 
      <div className="not-sent" style={{display: 'flex'}} id="not-sent">
        <div className="text-container">
          <div className="text-container-title">
            <PrismicRichText field={addBreaks(title.raw) || []} />
          </div>
          <div className="text-container-description">
            <PrismicRichText field={addBreaks(description.raw) || []} />
          </div>
        </div>
        <div className="form-container">
          <div className="name-container">
            <div className="brand-track-first-name-input">
              <div className="first-name-label" id="label">First Name</div>
              <input
                className={`first-name ${errors.firstname?.type} textline`}
                id="first-name"
                type="text"
                placeholder=""
                {...firstNameRegister}
                onChange={(e) => {
                  firstNameRegister.onChange(e);
                  setData('firstname', e.target.value)
                }}
              />
            </div>
            <div className="brand-track-last-name-input">
              <div className="last-name-label" id="label">Last Name</div>
              <input
                className={`last-name ${errors.lastname?.type} textline`}
                id="last-name"
                type="text"
                placeholder=""
                {...lastNameRegister}
                onChange={(e) => {
                  lastNameRegister.onChange(e);
                  setData('lastname', e.target.value)
                }}
              />
            </div>
            <div className="brand-track-company-name-input">
              <div className="company-label" id="label">Company Name</div>
              <input
                className={`company ${errors.company?.type} textline`}
                id="company"
                type="text"
                placeholder=""
                {...companyNameRegister}
                onChange={(e) => {
                  companyNameRegister.onChange(e);
                  setData('company', e.target.value)
                }}
              />
            </div>
            <div className="brand-track-title-input">
              <div className="title-label" id="label">Title</div>
              <input
                className={`title ${errors.company?.type} textline`}
                id="title"
                type="text"
                placeholder=""
                {...titleRegister}
                onChange={(e) => {
                  titleRegister.onChange(e);
                  setData('title', e.target.value)
                }}
              />
            </div>
          </div>
          <div className="email-and-phone-container">
            <div className="brand-track-email-input">
              <div className="email-label" id="label">Email *</div>
              <input
                className={`email ${errors.email?.type} textline`}
                type="text"
                placeholder=""
                {...emailRegister}
                onChange={(e) => {
                  emailRegister.onChange(e)
                  setData('email', e.target.value)
                }}
              />
            </div>
          </div>
          <div className="button-wrapper">
            <div className={`captcha ${notVerified ? 'not-verified' : 'verified'}`}>
              <ReCAPTCHA
                sitekey="6LfsCP8fAAAAANoUgdFHzLdc7CxAUkPLd-lfPA77"
                onChange={onReCAPTCHA}
                className="captcha"
              />
            </div>
            <input
              className="submit"
              type="submit"
              value="SUBMIT"
            />
          </div>
          {terms_and_conditions_text ? <div className="terms-and-conditions-text-container">
            <PrismicRichText field={terms_and_conditions_text.richText || []} />
          </div> : null}
        </div>
      </div>
      }
    </form>
  );
};

export default BrandTrackForm;
