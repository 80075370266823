const defaultLanguage = 'en-us';

/* The Link Resolver
* This function will be used to generate links to Prismic documents
As your project grows, you should update this function according to your routes */

const linkResolver = (doc) => {
  if (doc.type === 'homepage') {
    return doc.lang === defaultLanguage ? '/' : `/${doc.lang}`;
  }
  if (doc.type === 'contact') {
    return '/contact';
  }
  if (doc.type === 'test_playground') {
    return '/test-playground';
  }
  if (doc.type === 'page' || doc.type === 'blog' || doc.type === 'blog_post'
    || doc.type === 'podcast' || doc.type === 'podcast_post' || doc.type === 'knowledge_center'
    || doc.type === 'knowledge_center_article' || doc.type === 'test_playground_page' || doc.type === 'webinar' || doc.type === 'webinar_post') {
    let url = '';
    if (doc.data && doc.data.section) {
      url += `/${doc.data.section}`;
    }
    if (doc.lang === defaultLanguage) {
      url += `/${doc.uid}`;
    } else {
      url += `/${doc.lang}/${doc.uid}`;
    }
    return url;
  }

  return '/';
};

module.exports = linkResolver;
