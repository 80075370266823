import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Navigation from './slices/Navigation/Navigation';
import Footer from './slices/Footer/Footer';
import '../stylesheets/main.scss';

const Layout = ({
  children, navigationMenu, activeDocMeta, footerMenu, metaTitle, metaDescription,
}) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }`);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle || data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>
      <div className="website-wrapper">
        <Navigation
          navigationMenu={navigationMenu}
          className="navigation"
          activeDocMeta={activeDocMeta}
        />
        <main>{children}</main>
        <Footer footerMenu={footerMenu} />
      </div>
    </>
  );
};

export default Layout;
