import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';

const PageTemplate = ({ data }) => {
  if (!data) return null;
  const pageContent = data.prismicPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const metaTitle = pageContent.data.meta_title || `${pageContent.data.display_title.text} | CloudArmy`;

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={page.body} type={pageContent.type} metaTitle={metaTitle} />
    </Layout>
  );
};

export const query = graphql`
query pageQuery($uid: String, $lang: String) {
  prismicPage(uid: {eq: $uid}, lang: {eq: $lang}) {
    url
    uid
    type
    id
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    _previewable  
    data {
      meta_title
      display_title {
        html
        raw
        text
      }
      section
      body {
        ... on PrismicPageDataBodyAlternatingTextAndImageBlocks {
          id
          slice_label
          slice_type
          primary {
            title {
              html
              raw
              text
            }
          }
          items {
            image_position
            image {
              url
              alt
              gatsbyImageData
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image_below_text {
              url
              alt
              gatsbyImageData
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            text {
              html
              raw
              text
            }
          }
        }
        ... on PrismicPageDataBodyAlternatingFeatures {
          id
          slice_label
          slice_type
          primary {
            title {
              html
              raw
              text
            }
          }
          items {
            position
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            feature_title {
              html
              raw
              text
            }
            feature_description {
              raw
              html
              text
            }
          }
        }
      ... on PrismicPageDataBodyCircleFlow {
          id
          slice_label
          slice_type
          primary {
            title {
              html
              raw
              text
            }
          }
          items {
            flow_title {
              html
              raw
              text
            }
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            flow_description {
              raw
              html
              text
            }
          }
        }
        ... on PrismicPageDataBodyTextOnImageWithDescription {
          id
          slice_type
          slice_label
          primary {
            text_on_image {
              html
              raw
              text
            }
            secondary_description_title {
              html
              raw
              text
            }
            secondary_description {
              html
              raw
              text
            }
            description_below_image {
              html
              raw
              text
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyFullWidthImageWithTextBelow {
          id
          slice_label
          slice_type
          primary {
            title_color
            title {
              html
              raw
              text
            }
            description_color
            description_position
            description {
              html
              raw
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyFullWidthImageWithTextOnImage {
          id
          slice_type
          slice_label
          primary {
            title_color
            title {
              html
              raw
              text
            }
            description_color
            description {
              html
              raw
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyFullWidthImageWithButtons {
          id
          slice_label
          slice_type
          primary {
            title_color
            title {
              html
              raw
              text
            }
            sub_title_color
            sub_title {
              html
              raw
              text
            }
            description_color
            description {
              html
              raw
              text
            }
            image {
              alt
              gatsbyImageData
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            mobile_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          items {
            button_color
            button_enabled
            button_text_color
            button_text {
              html
              raw
              text
            }
            button_fragment {
              html
              raw
              text
            }
            button_url {
              url
              document {
                ... on PrismicPage {
                  id
                  url
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyFullWidthMedia {
          id
          items {
            location {
              html
              raw
              text
            }
            button
            button_text {
              html
              raw
              text
            }
            button_url {
              url
              document {
                ... on PrismicPage {
                  id
                  url
                }
              }
            }
            button_fragment {
              html
              raw
              text
            }
            button_color
            button_text_color
          }
          primary {
            title_font_size
            description {
              html
              raw
              text
            }
            media {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2, placeholder: BLURRED,
                      blurredOptions: { width: 250, toFormat: AUTO})
                }
              }
            }
            mobile_media {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            alt
            title {
              html
              raw
              text
            }
            text_position
            description_position
            description_color
            title_color
            mini_title {
              html
              raw
              text
            }
            media_above_background {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              copyright
              alt
            }
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyTextInfo {
          id
          slice_label
          slice_type
          primary {
            description {
              html
              raw
              text
            }
            description_html {
                text
            }
            title {
              html
              raw
              text
            }
            icon {
              alt
              copyright
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300, layout: CONSTRAINED, placeholder: BLURRED,
                      blurredOptions: { width: 250, toFormat: AUTO})
                }
              }
              url
            }
            title_color
            description_color
            title_alignment
            description_alignment
            dot_line
            width
            center_vertically
            font_family
            padding_top
            background_color
            temp_nudgestock_styling
          }
          items {
            image_alignment
            image_with_text {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 200, height: 200)
                }
              }
            }
            text_with_aligned_image {
              html
              raw
              text
            }
          }
        },
        ... on PrismicPageDataBodyTextWithAction {
          id
          items {
            link {
              link_type
              raw
              url
              lang
              document {
                ... on PrismicPage {
                  url
                }
                ... on PrismicBlog {
                  url
                }
                ... on PrismicPodcast {
                  url
                }
                ... on PrismicKnowledgeCenter {
                  url
                }
                ... on PrismicContact {
                  url
                }
              }
            }
            link_title {
              html
              raw
              text
            }
            description {
              html
              raw
              text
            }
            link_opens_to_a_new_tab
          }
          slice_label
          slice_type
          primary {
            text {
              html
              raw
              text
            }
            title {
              html
              raw
              text
            }
            logo {
              alt
              copyright
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
              url
            }
            title_color
            description_color
            text_width
            logo_above_text
            background
          }
        },
        ... on PrismicPageDataBodyConceptSnippet {
          id
          items {
            learn_more
            url {
              document {
                ... on PrismicPage {
                  url
                }
              }
            }
            description {
              html
              text
              raw
            }
            icon {
              alt
              copyright
              dimensions {
                height
                width
              }
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 100, placeholder: NONE)
                }
                publicURL
              }
            }
            title {
              html
              raw
              text
            }
          }
          primary {
            description {
              html
              raw
              text
            }
            title {
              html
              raw
              text
            }
            description_color
            title_color
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyProcessFlow {
          id
          items {
            description {
              html
              raw
              text
            }
            icon {
              alt
              copyright
              dimensions {
                height
                width
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 100, height: 100, layout: CONSTRAINED, placeholder: NONE)
                }
                publicURL
              }
              url
            }
            title {
              html
              raw
              text
            }
          }
          primary {
            description {
              html
              raw
              text
            }
            title {
              html
              raw
              text
            }
            description_color
            title_color
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyTextWithMediaVertical {
          id
          primary {
            bullet_points {
              html
              raw
              text
            }
            description {
              html
              raw
              text
            }
            large_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
              url
            }
            alt
            small_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
            title {
              html
              raw
              text
            }
            title_color
            description_color
            bullet_point_color
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyHorizontallyExpandableList {
          id
          items {
            list_description {
              html
              raw
              text
            }
            list_media {
              url
              raw
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900, placeholder: NONE)
                }
              }
              link_type
            }
            list_title {
              html
              raw
              text
            }
            link {
              url
              document {
                ... on PrismicPage {
                  id
                  url
                }
              }
            }
          }
          primary {
            description {
              html
              raw
              text
            }
            title {
              html
              raw
              text
            }
            title_color
            description_color
            size
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyFullWidthTextWithImageBlock {
          id
          items {
            metrics {
              html
              raw
              text
            }
          }
          primary {
            description {
              html
              raw
              text
            }
            icon {
              alt
              copyright
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 350, height: 350, layout: CONSTRAINED, placeholder: NONE)
                }
                publicURL
              }
            }
            title {
              html
              raw
              text
            }
            image {
              alt
              copyright
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 500, placeholder: NONE)
                }
              }
              url
            }
            metrics_title
            metrics_color
            text_alignment
            alignment
            title_color
            description_color
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyEmbeddedMedia {
          id
          primary {
            media {
              link_type
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 500, layout: CONSTRAINED, placeholder: BLURRED, 
                      blurredOptions: { width: 250, toFormat: AUTO})
                }
              }
              url
              uid
              type
              raw
            }
            alt
            title {
              html
              raw
              text
            }
            youtube_id
            embed_url
            width
            height
            title_color
            title_placement
            gif {
              url
            }
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyImageGrid {
          id
          items {
            description {
              html
              raw
              text
            }
            image {
              alt
              copyright
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
                publicURL
              }
              url
            }
            image_title {
              html
              raw
              text
            }
            link {
              document {
                ... on PrismicPage {
                  url
                }
              }
            }
          }
          primary {
            title {
              html
              raw
              text
            }
            solutions_or_about
          }
          slice_label
          slice_type
        }
        ... on PrismicPageDataBodyForm {
          id
          slice_type
          slice_label
          primary {
            description {
              html
              raw
              text
            }
            fields
            title_color
            description_color
            logo_above_text
            form_id
            portal_id
            title {
              html
              raw
              text
            }
            landing_page_title_background_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            bottom_text {
              html
              raw
              text
            }
            why_are_you_contacting_us {
              html
              raw
              text
            }
            redirect {
              url
              document {
                ... on PrismicPage {
                  id
                  url
                }
              }
            }
            text_after_submit {
              html
              raw
              text
            }
            google_conversion_name
          }
          items {
            how_did_you_hear_about_us
          }
        }
        ... on PrismicPageDataBodyFormWithImage {
          id
          slice_label
          items {
            area_of_interest
          }
          primary {
            title {
              html
              raw
              text
              richText
            }
            portal_id
            image {
              alt
              url
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            form_id
            description {
              text
              richText
              raw
              html
            }
            text_after_submit {
              html
              raw
              text
              richText
            }
          }
          slice_type
        }
        ... on PrismicPageDataBodyQuoteCarousel {
          id
          items {
            quotes {
              html
              raw
              text
            }
            image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 115, height: 115, layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
          primary {
            title {
              html
              raw
              text
            }
          }
          slice_label
          slice_type
        },
        ... on PrismicPageDataBodyGrid {
            id
            primary {
              title {
                raw
                html
                text
              }
            }
            items {
              method
              link {
                url
                document {
                  ... on PrismicPage {
                    url
                  }
                  ... on PrismicBlog {
                    url
                  }
                  ... on PrismicBlogPost {
                    url
                  }
                  ... on PrismicPodcast {
                    url
                  }
                  ... on PrismicKnowledgeCenter {
                    url
                  }
                  ... on PrismicContact {
                    url
                  }
                  ... on PrismicWebinar {
                    url
                  }
                  ... on PrismicWebinarPost {
                    url
                  }
                }
              }
              title {
                html
                raw
                text
              }
              author {
                html
                raw
                text 
              }
              text_position
              image {
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              gif {
                url
              }
            }
            slice_label
            slice_type
          },
          ... on PrismicPageDataBodyTextWithIcons {
            id
            items {
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 100, layout: CONSTRAINED, placeholder: NONE)
                  }
                }
                copyright
                alt
                url
              }
              icon_title {
                html
                raw
                text
              }
            }
            primary {
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
        },
        ... on PrismicPageDataBodyTextWithAlignedIcon {
            id
            primary {
              slice_alignment
              title {
                raw
                html
                text
              }
              icon {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                url
              }
              title_alignment
              description_alignment
              description {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageDataBodyProfile {
            id
            primary {
              title {
                html
                raw
                text
              }
              name {
                html
                raw
                text
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                alt
                copyright
              }
              description {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageDataBodyAlternatingArticles {
            id
            slice_label
            slice_type
            items {
              title {
                text
                richText
                raw
                html
              }
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              description {
                text
                richText
                html
                raw
              }
              image_position
              article_url {
                url
                document {
                  ... on PrismicPage {
                      id
                      url
                  }
                }
              }
              podcast_url
          }
        }
        ... on PrismicPageDataBodyIdeasReactorForm {
          id
          slice_type
          slice_label
          primary {
            portal_id
            google_conversion_name
            form_id
          }
        }
        ... on PrismicPageDataBodyBrandTrackForm {
          id
          slice_label
          slice_type
          primary {
            title {
              html
              raw
              richText
              text
            }
            submit_text {
              html
              raw
              richText
              text
            }
            portal_id
            form_position
            google_conversion_name
            form_id
            description {
              html
              raw
              richText
              text
            }
            terms_and_conditions_text {
              html
              raw
              richText
              text
            }
          }
        }
        ... on PrismicPageDataBodyContactUs {
          id
          slice_label
          slice_type
          primary {
            title {
              html
              raw
              richText
              text
            }
            title_color
            description {
              html
              raw
              richText
              text
            }
            description_color
            description_position
            has_tag_filter
          }
          items {
            link {
              document {
                ... on PrismicPage {
                  id
                  url
                }
              }
            }
            job_title {
              html
              raw
              richText
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            employee_tag {
              html
              raw
              text
              richText
            }
            employee_name {
              html
              raw
              richText
              text
            }
          }
        }
        ... on PrismicPageDataBodyBasicForm {
          id
          slice_label
          slice_type
          primary {
            form_id
            google_conversion_name
            portal_id
            title {
              html
              raw
              richText
              text
            }
            text_after_submit {
              html
              raw
              richText
              text
            }
            description {
              html
              raw
              richText
              text
            }
          }
        }
        ... on PrismicPageDataBodyNudgestockForm {
          id
          slice_type
          primary {
            description {
              html
              richText
              raw
              text
            }
            form_id
            google_conversion_name
            portal_id
            privacy_text {
              html
              raw
              richText
              text
            }
            text_after_submit {
              html
              raw
              richText
              text
            }
            title {
              html
              raw
              richText
              text
            }
          }
          slice_label
        }
        ... on PrismicPageDataBodyTextInfoWithSideImage {
          id
          slice_type
          slice_label
          primary {
            title_color
            title {
              html
              raw
              richText
              text
            }
            image_position
            description_color
            description {
              html
              raw
              richText
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
  prismicNavigation(lang: {eq: $lang}) {
      ...NavigationFragment
  }
  prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
  }
}

`;

export default withPrismicPreview(PageTemplate);
