import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './ImageGrid.scss';
import { Link } from 'gatsby';

const ImageGrid = ({ slice }) => {
  const {
    solutions_or_about,
    title_color,
    title,
  } = slice.primary;
  const pageType = solutions_or_about.toLowerCase();

  const ifNoImage = (item) => {
    if (item.length) {
      return (
        <img
          src={item.image.localFile?.publicURL}
          alt={item.image.alt}
        />
      )
    } else {
      return (
        <div className="logo" />
      )
    }
  };

  return (
    <section className="image-grid">
      <div className="container">
        <div className={`title ${title_color}`}>
          <PrismicRichText field={title.raw || []} />
        </div>
        <div className="images-container">
          {slice.items.map((item, index) => {
            const image = getImage(item.image.localFile);
            return (
              <div className={`images ${pageType}`} key={index}>
                <Link
                  className="image-link-wrapper"
                  to={item.link?.document?.url || undefined}
                >
                  {item.image.localFile?.childImageSharp ?
                    <GatsbyImage
                      className="logo"
                      image={image}
                      alt={item.image.alt || ''}
                      loading="eager"
                      objectFit={pageType === 'solutions' ? 'scale-down' : ''}
                    /> :
                    ifNoImage(item)
                  }
                </Link>
                {pageType === 'about'
                  && (
                    <div className="person">
                      <Link
                        to={item.link?.document?.url || undefined}
                      >
                        <div className="name">{asText(item.image_title.raw).toUpperCase()}</div>
                        <div className="position">{asText(item.description.raw).toUpperCase()}</div>
                      </Link>
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default ImageGrid;
