import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './TextWithMediaVertical.scss';

const TextWithMediaVertical = ({ slice }) => {
  const {
    title,
    title_color,
    description,
    description_color,
    bullet_points,
    bullet_point_color,
    alt,
  } = slice.primary;
  const largeImage = getImage(slice.primary.large_image.localFile);
  const smallImage = getImage(slice.primary.small_image.localFile);

  return (
    <section className="text-with-media-vertical">
      <div className="content-container">
        <div className="image-wrapper">
          <GatsbyImage
            className="image"
            image={largeImage}
            alt={alt || ''}
            objectFit="cover"
          />
        </div>
        <div className="right-container">
          <div className={`title ${title_color}`}>
            {asText(title.raw).toUpperCase()}
          </div>
          <div className={`description ${description_color}`}>
            {asText(description.raw)}
          </div>
          <div className="bullet-and-image-container">
            <div className={`bullet-point-container ${bullet_point_color}`}>
              <PrismicRichText
                field={bullet_points.raw}
              />
            </div>
            <GatsbyImage
              className="image"
              image={smallImage}
              alt="placeholder"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextWithMediaVertical;
