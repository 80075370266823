import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import GridItem from '../../components/slices/GridItem/GridItem';
import './PodcastPost.scss';
import Tags from '../../components/Tags/Tags';

const PodcastPostTemplate = ({ data, pageContext }) => {
  if (!data) return null;

  const podcasts = pageContext.podcast;

  const podcastPostContent = data.prismicPodcastPost;
  const { lang, type, url } = podcastPostContent;
  const alternateLanguages = podcastPostContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};

  function createMarkup() {
    const output = podcasts.embedInfo.html ? podcasts.embedInfo.html.replace('" height="200"', '?&hide_share=true" height="52') : '';
    return { __html: output };
  }
  const metaTitle = `${podcasts.title} | CloudArmy Podcast`;

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <div className="podcast-post-container">
        <img
          className="image"
          src={podcasts.image_url}
          alt={podcasts.title}
          width={350}
        />
        <div className="slice-container">
          <h1 className="title">
            <span className="episode">
              EPISODE&nbsp;
              {podcasts.number < 10 ? `0${podcasts.number}` : podcasts.number}
              :
            </span>
            <span className="space">&nbsp;</span>
            {podcasts.title.toUpperCase()}
          </h1>
          <div className="description">
            {podcasts.description}
            <div dangerouslySetInnerHTML={createMarkup()} />
          </div>
          <div className="interactables">
            <Tags
              url={`https://cloud.army/podcast/episode/${podcasts.slug}`}
              podcast
              desktop
              alignment="left"
            />
          </div>
        </div>
      </div>
      {/* <GridItem posts={uniquePosts} extraInfo={false} /> */}
    </Layout>
  );
};

export const query = graphql`
  query podcastPostQuery($lang: String, $uid: String, $id: String) {
    prismicPodcastPost(uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
    }
    prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(PodcastPostTemplate);
