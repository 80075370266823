import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './SocialIcons.scss';

const SocialIcons = ({ slice }) => (
  <section className="social-icons">
    {slice.items.map((item, index) => {
      const {
        icon,
        url,
      } = item;
      const image = getImage(icon.localFile);
      return (
        <a
          className="social"
          href={url.url}
          target="_blank"
          rel="noreferrer"
          key={index}
        >
          <GatsbyImage
            image={image}
            alt={icon.alt || ''}
            className="icon"
            loading="eager"
          />
          <span className="hover-circle" />
        </a>
      );
    })}
  </section>
);
export default SocialIcons;
