/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import LanguageSwitcher from '../../LanguageSwitcher';

const Footer = ({ footerMenu }) => {
  const Socials = () => (
    <div className="social">
      {footerMenu.body[0].items.map((item, index) => {
        const image = getImage(item.icon.localFile);
        return (
          <a href={item.url.url} key={index}>
            <GatsbyImage image={image} alt={item.icon.alt || ''} />
          </a>
        );
      })}
    </div>
  );

  const Reserved = () => (
    <div className="reserved">
      {footerMenu.body[1].items.map((item, index) => {
        const image = getImage(item.icon.localFile);
        return (
          <div key={index}>
            <GatsbyImage image={image} alt={item.icon.alt || ''} className="logo" />
            <p className="copyright">{footerMenu.body[1].primary.title.text}</p>
          </div>
        );
      })}
    </div>
  );

  const Privacy = () => (
    <div className="privacy">
      <div className="rows">
        {footerMenu.body[2].items.map((item, index) => (
          <Link to={item.link.url} key={index}>
            <PrismicRichText field={item.title.raw || []} />
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <footer>
      <div className="desktop">
        <Socials />
        <Reserved />
        <Privacy />
      </div>
      <div className="mobile">
        <Reserved />
        <Socials />
        <Privacy />
      </div>
    </footer>
  );
};
export const query = graphql`
fragment FooterFragment on PrismicFooter {
  lang
  data {
    body {
      ... on PrismicFooterDataBodyLinkWithText {
        id
        slice_type
        slice_label
        items {
          link {
            url
          }
          title {
            html
            raw
            text
          }
        }
      }
      ... on PrismicFooterDataBodySocialIcons {
        id
        slice_label
        slice_type
        items {
          url {
            url
          }
          icon {
            alt
            copyright
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      ... on PrismicFooterDataBodyTextWithIcons {
        id
        slice_label
        slice_type
        primary {
          footer
          title {
            html
            raw
            text
          }
        }
        items {
          icon {
            alt
            copyright
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
}
`;
export default Footer;
