import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './TextWithIcons.scss';
import { Link } from 'gatsby';

const TextWithIcons = ({ slice }) => {
  const {
    title,
    width,
    description,
    link,
    fragment,
  } = slice.primary;
  return (
    <section className={`text-with-icons ${width}`}>
      <div className="text-with-icons-container">
        <div className="title">
          {asText(title.raw).toUpperCase()}
        </div>
        {description && description.text.length > 0 && (
          <div className="description">
            {asText(description.raw).toUpperCase()}
          </div>
        )}
        <div className="icon-container">
          {slice.items.map((item, index) => {
            const image = getImage(item.icon.localFile);
            return (
              <Link key={index} to={fragment ? `${link.document?.url}#${fragment}` : link.document?.url}>
              <div className="icon-wrapper" key={index}>
                {item.bottom_text?.raw && <div className="icon-bottom-text">
                  <PrismicRichText field={item.bottom_text.raw} />
                </div>}
                <div className="icon">
                  {item.icon.localFile?.childImageSharp ?
                    <GatsbyImage
                      image={image}
                      alt={item.icon.alt || ''}
                      objectFit="contain"
                    />
                    : <img
                        src={item.icon.url}
                        alt={item.icon.alt}
                    />
                  }
                  <span className="hover-circle" />
                </div>
                <span className="icon-text">
                  {asText(item.icon_title.raw).toUpperCase()}
                </span>
              </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default TextWithIcons;
