import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import './TextWithAlignedIcon.scss';
import { Link } from 'gatsby';

const TextWithAlignedIcon = ({ slice }) => {
  const {
    title,
    title_alignment,
    description,
    description_alignment,
    icon,
    slice_alignment,
  } = slice.primary;
  const image = getImage(icon.localFile);

  return (
    <section className={`text-with-aligned-icon ${slice_alignment}`}>
      <div className="container">
        <div className={`image-container ${image ? 'has-icon' : 'no-icon'}`}>
          <div className={`title-wrapper ${image ? 'has-icon' : 'no-icon'}`}>
            <div className={`title ${title_alignment}`}>
              <PrismicRichText field={title.raw} />
            </div>
            <div className="blue-line" />
            <div className={`description ${description_alignment}`}>
              <PrismicRichText field={description.raw} />
            </div>
          </div>
          <GatsbyImage
            image={image}
            className="icon"
            alt={slice.primary.icon.alt}
            loading="eager"
            objectFit="contain"
          />
        </div>
      </div>
    </section>
  );
};
export default TextWithAlignedIcon;
