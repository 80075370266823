import React, { useState, useEffect } from 'react';
import './BasicForm.scss';
import { useForm, Controller } from 'react-hook-form';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { asText } from '@prismicio/helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import { PrismicRichText } from '@prismicio/react';

const BasicForm = ({ slice, metaTitle }) => {
  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm();
  const {
    title,
    description,
    portal_id,
    form_id,
    image,
    google_conversion_name,
    text_after_submit,
  } = slice.primary;

  const [captchaValue, setCaptchaValue] = useState("");
  const [notVerified, setNotVerified] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [updateState, setUpdateState] = useState([]);
  const firstNameRegister = register('firstname', { required: true });
  const lastNameRegister = register('lastname', { required: true });
  const emailRegister = register('email', { required: true });
  
  const consent = {
    consent: {
      consentToProcess: true,
      text: 'I agree to allow CloudArmy to store and process my personal data.',
      communications: [
        {
          value: true,
          subscriptionTypeId: 999,
          text: 'I agree to receive marketing communications from CloudArmy.',
        },
      ],
    },
  };

  const onReCAPTCHA = (value) => {
    if (value) {
      setNotVerified(false);
    }
    setCaptchaValue(value);
  }
  const [formData, setFormData] = useState({
    fields: [
      {
        name: 'firstname',
        value: '',
      },
      {
        name: 'lastname',
        value: ''
      },
      {
        name: 'email',
        value: '',
      },
    ],
  });

  const setData = (key, value) => {
    const objIndex = formData.fields.findIndex(((obj) => obj.name === key));
    formData.fields[objIndex].value = value;
    let newState = [...formData.fields];
    setUpdateState(newState);
  };

  const handleHubspotSubmit = (event) => {
    if (captchaValue?.length) {
      const timestamp = new Date();

      const xhr = new XMLHttpRequest();
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portal_id}/${form_id}`;

      formData.legalConsentOptions = consent;

      const finalData = JSON.stringify(formData);

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setSentMessage(true); // success
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(xhr.responseText); // Returns a 404 error if the formGuid isn't found
        }
      };
      xhr.send(finalData);
      window.dataLayer.push({
        'event': google_conversion_name,
        'hs-form-guid': form_id,
      });
    } else {
      setNotVerified(true);
    }
  };

  return (
    <form className="basic-form-container" onSubmit={handleSubmit(handleHubspotSubmit)}>
      {sentMessage ? 
      <div className="sent">
        {text_after_submit?.text?.length ? <PrismicRichText field={text_after_submit?.richText || []} /> : 'Thank you for entering your information.'}
      </div> 
      : 
      <div className="not-sent" style={{display: 'flex'}}>
        <div className="basic-form-only-image-container">
          {image?.localFile ? <GatsbyImage
            className="basic-form"
            image={getImage(image.localFile)}
            loading="eager"
            objectFit="unset"
            alt={image.alt || ''}
            height={763}
            width={883}
            /> : null}
          </div>
          <div className={`basic-form-wrapper ${image?.localFile ? '' : 'no-image'}`}>
            {(title.text.length || description.text.length) ? <div className="basic-form-text-container">
              <div className="basic-form-title">
                {asText(title.raw)}
              </div>
              <div className="basic-form-description">
                {asText(description.raw)}
              </div>
            </div> : null}
            <div className="basic-form-input">
              <div className="basic-form-first-name-input">
                <div className="first-name-label">First Name</div>
                <input
                  className={`first-name ${errors.firstname?.type}`}
                  id="first-name"
                  type="text"
                  placeholder=""
                  {...firstNameRegister}
                  onChange={(e) => {
                    firstNameRegister.onChange(e);
                    setData('firstname', e.target.value)
                  }}
                />
              </div>
              <div className="basic-form-last-name-input">
                <div className="last-name-label">Last Name</div>
                <input
                  className={`last-name ${errors.lastname?.type}`}
                  id="last-name"
                  type="text"
                  placeholder=""
                  {...lastNameRegister}
                  onChange={(e) => {
                    lastNameRegister.onChange(e);
                    setData('lastname', e.target.value)
                  }}
                />
              </div>
              <div className="basic-form-email-input">
                <div className="email-label">Email Address</div>
                <input
                  className={`email ${errors.email?.type}`}
                  type="text"
                  placeholder=""
                  {...emailRegister}
                  onChange={(e) => {
                    emailRegister.onChange(e)
                    setData('email', e.target.value)
                  }}
                />
              </div>
              <div className="button-wrapper">
                <div className={`captcha ${notVerified ? 'not-verified' : 'verified'}`}>
                  <ReCAPTCHA
                    sitekey="6LfsCP8fAAAAANoUgdFHzLdc7CxAUkPLd-lfPA77"
                    onChange={onReCAPTCHA}
                    className="captcha"
                  />
                </div>
                <input
                  className="submit"
                  type="submit"
                  value="Subscribe"
                />
                <span className="hover-circle normal" />
                <div className="terms-text">
                  By entering your email, you agree to receive promotional content and you agree to our <a className="terms-link" href="https://cloud.army/terms">terms and conditions</a>.
                </div>
              </div>
            </div>
          </div>
      </div>}
    </form>
  );
};

export default BasicForm;
