import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import './ProcessFlow.scss';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProcessFlow = ({ slice }) => {
  const {
    title_color,
    title,
    description,
    description_color,
  } = slice.primary;

  return (
    <section className="process-flow" id="process-flow">
      <div className={`title ${title_color}`}>
        <PrismicRichText field={title.raw || []} />
      </div>
      <div className={`title-description ${description_color}`}>
        {asText(description.raw)}
      </div>
      <div className="flow-container">
        {slice.items.map((item, index) => {
          const image = getImage(item.icon.localFile);
          return (
            <div className="flow" key={index}>
              <div className={`flow-title ${index === slice.items.length - 1 ? 'last' : ''}`}>
                {asText(item.title.raw).toUpperCase()}
              </div>
              <div className={`icon-container ${index === slice.items.length - 1 ? 'last' : ''} ${index % 2 === 0 ? 'even' : 'odd'}`}>
                {item.icon.localFile?.childImageSharp ? (
                  <GatsbyImage
                    className="icon"
                    image={image}
                    alt="placeholder"
                    loading="eager"
                  />
                )
                  : (
                    <img
                      src={item.icon.localFile?.publicURL}
                      alt={item.icon.alt || ''}
                      width={70}
                    />
                  )}
                <span className="hover-circle" />
              </div>
              <div className="flow-description">
                {asText(item.description.raw)}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ProcessFlow;
