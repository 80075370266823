import React, { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import './AlternatingArticles.scss';
import { PrismicRichText } from '@prismicio/react';

const {
  simpleCastToken,
} = require('../../../../prismic-configuration');

const AlternatingArticles = ({ slice }) => {
  const [updatedSlices, setUpdatedSlices] = useState(null);
  const staticOrDynamicSlice = slice?.items || slice;

  useEffect(() => {
    const getEmbedPlayer = async(podcast_url, index) => {
      try {
        const options = {
          authorization: simpleCastToken,
          method: 'GET',
          redirect: 'follow',
        };
        const response = await fetch(`https://api.simplecast.com/oembed?url=${podcast_url}`, options);
        const responseData = await response.json();
        responseData.html ? slice.items[index].embedInfo = responseData : slice.items[index].embedInfo = null;
        setUpdatedSlices(slice);
      } catch (err) {
        console.log(err);
      }
    };
    slice?.items?.map((item, index) => {
      if (item.podcast_url) {
        getEmbedPlayer(item.podcast_url, index);
      }
    });
  }, []);

  const createMarkup = (iframe) => {
    const output = iframe.html ? iframe.html.replace('" height="200"', '?&hide_share=true" height="52') : '';
    return { __html: output };
  };
  return (
    <section className="article-excerpt">
      <div className="all-articles">
        {staticOrDynamicSlice.map((item, index) => {
          const itemData = item.data || item;
          const titleData = itemData?.display_title || itemData?.title;
          const image = getImage(itemData?.image?.localFile);
          const articleUrl = item.article_url?.document?.url || item.article_url?.url;
          const url = articleUrl || item.url;
          return (
            <div
              className={`article-container ${item?.image_position ? item.image_position?.toString() : index % 2 === 0 ? 'left' : 'right'}`}
              key={item.id}
            >
              <Link to={url}>
                <GatsbyImage
                  className="image"
                  image={image}
                  alt={itemData.image.alt || ''}
                  loading="eager"
                  objectFit="cover"
                />
              </Link>
              <div className="info-wrapper">
                <div className="text-wrapper">
                  <Link to={url}>
                    <div className="title">
                      {titleData.richText.length < 0 ? <span className="space">&nbsp;</span> : null}
                      <PrismicRichText field={titleData?.richText || []} />
                    </div>
                  </Link>
                  <div className="description">
                    <Link to={articleUrl}>
                      <PrismicRichText field={itemData.description.richText || []} />
                    </Link>
                    {updatedSlices?.items[index]?.podcast_url ? <div dangerouslySetInnerHTML={createMarkup(updatedSlices.items[index]?.embedInfo)} /> : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AlternatingArticles;
