import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import LearnMore from '../../LearnMore/LearnMore';
import './ExpandableDescriptionList.scss';

const ExpandableDescriptionList = ({ slice }) => {
  const [expand, setExpand] = useState(false);
  const [descriptionIndex, setDescriptionIndex] = useState(0);
  const [type, setType] = useState('');

  const {
    title,
    alignment,
    learn_more,
    findability_icon,
    background_image,
  } = slice.primary;

  const expandDescription = (item, index) => {
    if (type === item) {
      setExpand(!expand);
    } else {
      setExpand(true);
    }
    setDescriptionIndex(index);
    setType(item);
  };

  return (
    <section className="expandable-description-list">
      <GatsbyImage
          className="image"
          image={getImage(background_image.localFile)}
          alt={background_image.alt || ''}
          loading="eager"
          objectFit="cover"
        />
      <div className={`list-wrapper ${alignment}`}>
        <div className="title">
          {asText(title.raw).toUpperCase()}
        </div>
        <div className="list">
          {slice.items.map((item, index) => {
            const expandSpecificItem = expand && descriptionIndex === index;
            return (
              <div
                className={`dropdown-name ${expandSpecificItem ? 'expanded' : ''} ${alignment}`}
                key={index}
                onClick={() => expandDescription(item, index)}
                role="button"
                tabIndex={0}
                onKeyDown={() => expandDescription(item, index)}
              >
                <div className="text-icon-wrapper">
                  <span className="text">{item.text.text.toUpperCase()}</span>
                  {expandSpecificItem ? (
                    <StaticImage
                      className="icon"
                      src="../../../images/icons/dropdown-close-yellow.png"
                      alt="dropdown"
                    />
                  )
                    : (
                      <StaticImage
                        className="icon"
                        src="../../../images/icons/dropdown-yellow.png"
                        alt="dropdown"
                      />
                    )}
                </div>
                {expandSpecificItem && (
                  <div>
                    <div className="description">
                      <PrismicRichText field={item.description.raw || []} />
                    </div>
                    <div className="learn-more-container">
                      {learn_more &&
                      <LearnMore
                        url={item.link.document?.url}
                        fragment={item.fragment}
                        type={learn_more}
                        />
                      }
                      {findability_icon
                      && (
                        <Link to={item.fragment ? `${item.link.document?.url}#${item.fragment}` : item.link.document?.url }>
                          <StaticImage
                            className="findability"
                            src="../../../images/icons/findability.png"
                            alt="pin"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

    </section>
  );
};

export default ExpandableDescriptionList;
