/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react';
import { asText } from '@prismicio/helpers';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import logo from '../../../images/ca-logo.png';
// import LanguageSwitcher from '../../LanguageSwitcher';

const Navigation = ({ navigationMenu, activeDocMeta }) => {
  const [expandHeader, setExpandHeader] = useState(false);
  const [expandSubMenu, setExpandSubMenu] = useState(false);
  const [menuType, setMenuType] = useState('');
  const [menuIndex, setMenuIndex] = useState(0);
  const openBurger = () => {
    const displayMobile = document.getElementById('mobile');

    if (displayMobile.style.display === 'flex') {
      displayMobile.style.display = 'none';
      setExpandSubMenu(false);
    } else {
      displayMobile.style.display = 'flex';
      setExpandSubMenu(true);
    }
  };

  const renderedMenuLinks = navigationMenu.nav
    ? navigationMenu.nav.map((menuLink, index) => {
      const expandAndSetMenu = () => {
        const { text } = menuLink.primary.label;

        if (menuType === text) {
          setExpandHeader(!expandHeader);
        } else {
          setExpandHeader(true);
        }

        setMenuType(text);
        setMenuIndex(index);
      };
      return (
        <li key={`top-nav-${index}`}>
          <p className="menu-links" onClick={expandAndSetMenu}>
            {!menuLink.primary.link.url && (
              <span>
                {asText(menuLink.primary.label.raw).toUpperCase()}
              </span>
            )}
            {menuLink.primary.link.url && (
              <Link to={menuLink.primary.link.url}>
                <span>
                  {asText(menuLink.primary.label.raw).toUpperCase()}
                </span>
              </Link>
            )}

            <span className="hover-circle" />
          </p>
          {expandHeader
              && menuIndex === index
              && menuLink.items[0].sub_nav_link_label.text
            && (
              <div className="max-width">
                <ul className="sub-menu">
                  {menuLink.items.map(
                    (item, subIndex) => item.sub_nav_link_label.text && (
                      <li className="link-wrapper" key={subIndex}>
                        <Link
                          className="link"
                          to={
                            item.sub_nav_link.document
                              ? item.sub_nav_link.document.url
                              : '/'
                          }
                        >
                          {item.sub_nav_link_label.text.toUpperCase()}
                        </Link>
                        <span className="hover-circle" />
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
        </li>
      );
    })
    : null;

  return (
    <div className="navigation-container">
      <header>
        <div className="menu">
          <Link to="/">
            <img src={logo} alt="Site logo" className="logo" />
          </Link>
          <div className="mobileNav">
            {!expandSubMenu && (
              <div onClick={openBurger}>
                <StaticImage
                  src="../../../images/icons/burger.png"
                  alt="Mobile Nav"
                />
              </div>
            )}
            {expandSubMenu && (
              <div onClick={openBurger}>
                <StaticImage
                  src="../../../images/icons/cross.png"
                  alt="Mobile Nav"
                />
              </div>
            )}
          </div>
        </div>
        <div className="menu-responsive" id="mobile">
          <ul>
            {renderedMenuLinks}
            {/* <LanguageSwitcher activeDocMeta={activeDocMeta} /> */}
          </ul>
        </div>
        {!expandSubMenu && (
          <div className="menu-responsive" id="desktop">
            <ul>
              {renderedMenuLinks}
              {/* <LanguageSwitcher activeDocMeta={activeDocMeta} /> */}
            </ul>
          </div>
        )}
      </header>
    </div>
  );
};
export const query = graphql`
  fragment NavigationFragment on PrismicNavigation {
    lang
    data {
      nav {
        ... on PrismicNavigationDataNavNavItem {
          id
          primary {
            label {
              raw
              text
            }
            link {
              url
              uid
              id
              link_type
              slug
              target
            }
          }
          items {
            sub_nav_link {
              document {
                ... on PrismicPage {
                  url
                }
                ... on PrismicBlog {
                  url
                }
                ... on PrismicPodcast {
                  url
                }
                ... on PrismicKnowledgeCenter {
                  url
                }
                ... on PrismicContact {
                  url
                }
                ... on PrismicWebinar {
                  url
                }
              }
            }
            sub_nav_link_label {
              text
            }
          }
          slice_type
          slice_label
        }
      }
    }
  }
`;
export default Navigation;
