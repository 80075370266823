import React from 'react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './FullWidthImageForBlog.scss';

const FullWidthImageForBlog = ({ slice, blogInfo, type }) => {
  const {
    title,
    author,
    author_title,
    date,
    preview_image,
    image_inside_blog_post,
  } = blogInfo;

  let formattedDate;
  if (blogInfo) {
    const newDate = new Date(date);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    formattedDate = `${monthNames[newDate.getMonth()]} ${newDate.getDate()} ${newDate.getFullYear()}`;
  }

  const image = getImage(image_inside_blog_post.localFile);

  return (
    <section className="full-width-image-for-blog">
      {image_inside_blog_post
        ? (
          <div className="image-container">
            <GatsbyImage
              className="image"
              image={image}
              alt={image?.alt || ''}
              loading="eager"
            />
          </div>
        ) : null}
      <div className="text-container">
        <div className="title">{asText(title.raw)}</div>
        <div className="author">
          BY:
          {' '}
          {author?.toUpperCase()}
          {author_title?.length ? `, ${author_title.toUpperCase()}` : ''}
        </div>
        <div className="date">
          Published on:
          {' '}
          {formattedDate}
        </div>
      </div>
    </section>
  );
};

export default FullWidthImageForBlog;
