import React from 'react';
import TextInfo from './slices/TextInfo/TextInfo';
import FullWidthMedia from './slices/FullWidthMedia/FullWidthMedia';
import FullWidthVideo from './slices/FullWidthVideo/FullWidthVideo';
import FullWidthImageWithTextBelow from './slices/FullWidthImageWithTextBelow/FullWidthImageWithTextBelow';
import TextWithAction from './slices/TextWithAction/TextWithAction';
import ConceptSnippet from './slices/ConceptSnippet/ConceptSnippet';
import MediaDescription from './slices/MediaDescription/MediaDescription';
import ExpandableDescriptionList from './slices/ExpandableDescriptionList/ExpandableDescriptionList';
import TextWithIcons from './slices/TextWithIcons/TextWithIcons';
import ImageGrid from './slices/ImageGrid/ImageGrid';
import QuoteCarousel from './slices/QuoteCarousel/QuoteCarousel';
import ProcessFlow from './slices/ProcessFlow/ProcessFlow';
import TextWithMediaVertical from './slices/TextWithMediaVertical/TextWithMediaVertical';
import HorizontallyExpandableList from './slices/HorizontallyExpandableList/HorizontallyExpandableList';
import FullWidthTextWithImageBlock from './slices/FullWidthTextWithImageBlock/FullWidthTextWithImageBlock';
import EmbeddedMedia from './slices/EmbeddedMedia/EmbeddedMedia';
import SocialIcons from './slices/SocialIcons/SocialIcons';
import GridItem from './slices/GridItem/GridItem';
import Form from './slices/Form/Form';
import TextWithDivider from './slices/TextWithDivider/TextWithDivider';
import Grid from './slices/Grid/Grid';
import TextWithAlignedIcon from './slices/TextWithAlignedIcon/TextWithAlignedIcon';
import Profile from './slices/Profile/Profile';
import AlternatingTextAndImageBlocks from './slices/AlternatingTextAndImageBlocks/AlternatingTextAndImageBlocks';
import AlternatingFeatures from './slices/AlternatingFeatures/AlternatingFeatures';
import CircleFlow from './slices/CircleFlow/CircleFlow';
import TextOnImageWithDescription from './slices/TextOnImageWithDescription/TextOnImageWithDescription';
import FullWidthImageWithTextOnImage from './slices/FullWidthImageWithTextOnImage/FullWidthImageWithTextOnImage';
import FullWidthImageWithButtons from './slices/FullWidthImageWithButtons/FullWidthImageWithButtons';
import FormWithImage from './slices/FormWithImage/FormWithImage';
import AlternatingArticles from './slices/AlternatingArticles/AlternatingArticles';
import IdeasReactorForm from './slices/Form/IdeasReactorForm/IdeasReactorForm';
import BrandTrackForm from './slices/Form/BrandTrackForm/BrandTrackForm';
import ContactUs from './slices/ContactUs/ContactUs';
import BasicForm from './slices/Form/BasicForm/BasicForm';
import NudgestockForm from './slices/Form/NudgestockForm/NudgestockForm';
import TextInfoWithSideImage from './slices/TextInfo/TextInfoWithSideImage/TextInfoWithSideImage';

const SliceZone = ({
  slices, blogInfo, type, metaTitle,
}) => {
  const sliceComponents = {
    full_width_media: FullWidthMedia,
    full_width_video: FullWidthVideo,
    text_info: TextInfo,
    text_with_action: TextWithAction,
    concept_snippet: ConceptSnippet,
    media_description: MediaDescription,
    expandable_description_list: ExpandableDescriptionList,
    text_with_icons: TextWithIcons,
    image_grid: ImageGrid,
    quote_carousel: QuoteCarousel,
    process_flow: ProcessFlow,
    text_with_media_vertical: TextWithMediaVertical,
    horizontally_expandable_list: HorizontallyExpandableList,
    full_width_text_with_image_block: FullWidthTextWithImageBlock,
    embedded_media: EmbeddedMedia,
    social_icons: SocialIcons,
    grid_item: GridItem,
    form: Form,
    text_with_divider: TextWithDivider,
    grid: Grid,
    text_with_aligned_icon: TextWithAlignedIcon,
    profile: Profile,
    alternating_text_and_image_blocks: AlternatingTextAndImageBlocks,
    alternating_features: AlternatingFeatures,
    circle_flow: CircleFlow,
    text_on_image_with_description: TextOnImageWithDescription,
    full_width_image_with_text_below: FullWidthImageWithTextBelow,
    full_width_image_with_text_on_image: FullWidthImageWithTextOnImage,
    full_width_image_with_buttons: FullWidthImageWithButtons,
    form_with_image: FormWithImage,
    alternating_articles: AlternatingArticles,
    ideas_reactor_form: IdeasReactorForm,
    brand_track_form: BrandTrackForm,
    contact_us: ContactUs,
    basic_form: BasicForm,
    nudgestock_form: NudgestockForm,
    text_info_with_side_image: TextInfoWithSideImage,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    const textInfoSlices = slices.every((s) => {
      if (s.slice_type === 'text_info') {
        return true;
      } return false;
    });
    if (SliceComponent) {
      return (
        <SliceComponent
          slice={slice}
          key={`slice-${index}`}
          blogInfo={blogInfo}
          type={type}
          metaTitle={metaTitle}
          isArticle={textInfoSlices}
        />
      );
    }
    return null;
  });
};

export default SliceZone;
