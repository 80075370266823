import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { StaticImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import Layout from '../../components/Layout';
import GridItem from '../../components/slices/GridItem/GridItem';
import './WebinarPost.scss';
import Tags from '../../components/Tags/Tags';
import SliceZone from '../../components/SliceZone';

const WebinarPostTemplate = ({ data }) => {
  if (!data) return null;
  const webinarPostMeta = data.prismicWebinarPost;
  const {
    date, description, display_title, image, body,
  } = data.prismicWebinarPost.data;
  const { lang, type, url } = webinarPostMeta;
  const alternateLanguages = webinarPostMeta.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};

  const metaTitle = `${display_title.text} | CloudArmy Webinar`;

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={body} />
    </Layout>
  );
};

export const query = graphql`
  query webinarPostQuery($lang: String, $uid: String, $id: String) {
    prismicWebinarPost(uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        body {
          ... on PrismicWebinarPostDataBodyForm {
            id
            slice_label
            slice_type
            primary {
              bottom_text {
                html
                text
                raw
                richText
              }
              description {
                html
                text
                raw
                richText
              }
              description_color
              fields
              form_id
              logo_above_text
              portal_id
              title_color
              why_are_you_contacting_us {
                html
                text
                raw
                richText
              }
              title {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                text
                raw
                richText
              }
              redirect {
                url
                document {
                  ... on PrismicWebinarPost {
                    id
                    url
                  }
                }
              }
              landing_page_title_background_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                alt
              }
              google_conversion_name
            }
            items {
              how_did_you_hear_about_us
            }
          }
          ... on PrismicWebinarPostDataBodyBasicForm {
          id
          slice_label
          slice_type
          primary {
            form_id
            google_conversion_name
            portal_id
            title {
              html
              raw
              richText
              text
            }
            text_after_submit {
              html
              raw
              richText
              text
            }
            description {
              html
              raw
              richText
              text
            }
          }
        }
        ... on PrismicWebinarPostDataBodyNudgestockForm {
          id
          slice_type
          primary {
            description {
              html
              richText
              raw
              text
            }
            form_id
            google_conversion_name
            portal_id
            privacy_text {
              html
              raw
              richText
              text
            }
            text_after_submit {
              html
              raw
              richText
              text
            }
            title {
              html
              raw
              richText
              text
            }
          }
          slice_label
        }
          ... on PrismicWebinarPostDataBodyEmbeddedMedia {
            id
            slice_type
            slice_label
            primary {
              youtube_id
              width
              title_placement
              title {
                richText
                raw
                html
                text
              }
              title_color
              media {
                raw
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              height
              gif {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              embed_url
              alt
            }
          }
          ... on PrismicWebinarPostDataBodyTextInfo {
            id
            slice_type
            slice_label
            primary {
              width
              title_color
              title_alignment
              title {
                richText
                text
                raw
                html
              }
              padding_top
              icon {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              font_family
              dot_line
              description_html {
                text
                richText
                html
                raw
              }
              description_color
              description_alignment
              description {
                text
                richText
                raw
                html
              }
              center_vertically
            }
          }
        }
        section
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          alt
        }
        display_title {
          html
          raw
          richText
          text
        }
        description {
          text
          raw
          richText
          html
        }
        date
      }
    }
    prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(WebinarPostTemplate);
