/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import MinimalForm from './MinimalForm';
import NudgestockForm from './NudgestockForm';
import ExpandedForm from './ExpandedForm';
import NudgestockSignupForm from './NudgestockSignupForm';
import './Form.scss';
import LandingPageForm from './LandingPageForm';

const Form = ({ slice, metaTitle }) => {
  const {
    title_color,
    title,
    description_color,
    description,
    fields,
    logo_above_text,
    form_id,
    portal_id,
    bottom_text,
    why_are_you_contacting_us,
    redirect,
    text_after_submit,
    landing_page_title_background_image,
    google_conversion_name,
  } = slice.primary;
  const [sentMessage, setSentMessage] = useState(false);
  const [showPdf, setShowPdf] = useState(false);

  useEffect(() => {
    if (sentMessage) {
      const url = redirect?.document?.url || redirect?.url;
      if (url) {
        setTimeout(function() {
          navigate(url);
        }, 2000)
      }
    }
  }, [sentMessage]);

  const consent = {
    consent: {
      consentToProcess: true,
      text: 'I agree to allow CloudArmy to store and process my personal data.',
      communications: [
        {
          value: true,
          subscriptionTypeId: 999,
          text: 'I agree to receive marketing communications from CloudArmy.',
        },
      ],
    },
  };

  const addBreaks = (raw) => {
    return raw.map((r) => {
      if (!r.text.length) {
        r.text = '\n';
      }
      return r;
    })
  }

  return (
    <section className={`form-wrapper ${logo_above_text} ${showPdf ? 'bottom-padding' : ''}`} id="form-wrapper">
      <div className="container">
        {sentMessage && (
          <div className="sent">
            {text_after_submit?.text?.length ? asText(text_after_submit.raw) : 'Thank you for submitting the form.'}
            {redirect?.url ? <div style={{marginTop: '5px'}}>You will shortly be redirected.</div> : null}
          </div>
        )}
        {!sentMessage && fields !== 'landing-page' && (
          <div className="text-wrapper">
            {title?.text?.length || title.length ? (
              <div className={`title ${title_color}`}>
                {title.raw ? asText(title.raw).toUpperCase() : title.toUpperCase()}
              </div>
            ) : null}
            {description?.text?.length || description.length ? (
              <div className={`description ${description_color}`}>
                {
                  description.raw
                    ? (
                      <PrismicRichText field={addBreaks(description.raw)} />
                    )
                    : description
                }
              </div>
            ) : null}
          </div>
        )}
        {!sentMessage && (
          <div className={`fields ${fields}`}>
            {fields === 'minimal' && (
              <MinimalForm
                consent={consent}
                onSend={() => setSentMessage(true)}
                portal_id={portal_id}
                form_id={form_id}
                why_are_you_contacting_us={why_are_you_contacting_us}
                google_conversion_name={google_conversion_name}
              />
            )}
            {fields === 'expanded' && (
              <ExpandedForm
                consent={consent}
                onSend={() => setSentMessage(true)}
                slice={slice}
                why_are_you_contacting_us={why_are_you_contacting_us}
                google_conversion_name={google_conversion_name}
              />
            )}
            {fields === 'nudgestock' && (
              <NudgestockForm
                consent={consent}
                onSend={() => setSentMessage(true)}
                slice={slice}
                why_are_you_contacting_us={why_are_you_contacting_us}
              />
            )}
            {fields === 'nudgestock-signup' && (
              <NudgestockSignupForm
                consent={consent}
                onSend={() => {
                  setSentMessage(true);
                  setShowPdf(false);
                }}
                slice={slice}
                why_are_you_contacting_us={why_are_you_contacting_us}
                google_conversion_name={google_conversion_name}
              />
            )}
            {fields === 'landing-page' && (
              <LandingPageForm
                consent={consent}
                onSend={() => {
                  setSentMessage(true);
                  setShowPdf(false);
                }}
                slice={slice}
                title={title}
                title_color={title_color}
                description={description}
                description_color={description_color}
                why_are_you_contacting_us={why_are_you_contacting_us}
                landing_page_title_background_image={landing_page_title_background_image}
                google_conversion_name={google_conversion_name}
              />
            )}
          </div>
        )}
        {bottom_text?.html?.length ? <div className="form-bottom-text">
          {bottom_text?.raw ? asText(bottom_text?.raw) : description}
        </div> : null}
      </div>
    </section>
  );
};
export default Form;
