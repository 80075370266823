import React, { useState } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import './TextOnImageWithDescription.scss';

const TextOnImageWithDescription = ({ slice }) => {
  const [showDescription, setShowDescription] = useState(false);
  const {
    text_on_image,
    secondary_description_title,
    secondary_description,
    description_below_image,
    image,
    icon,
  } = slice.primary;
  const prismicImage = getImage(image.localFile);
  const prismicIcon = getImage(icon.localFile);

  const addBreaks = (raw) => {
    return raw.map((r) => {
      if (!r.text.length) {
        r.text = '\n';
      }
      return r;
    })
  }

  return (
    <section className="text-on-image-with-description-container">
      <div className="text-on-image-with-description-image-container">
        <div className="top-image" onClick={() => setShowDescription(!showDescription)}>
          <div className={`image ${showDescription ? 'show' : 'hide'}-description`}>
            <GatsbyImage
              image={prismicImage}
              alt={prismicImage?.alt || ''}
              loading="eager"
              objectFit="cover"
              objectPosition="75% 0%"
            />
          </div>
          <div className="text-on-image">
            <div className="title">{asText(text_on_image.raw)}</div>
            <div className="dropdown">
              {!showDescription ? (
                <StaticImage
                  className="icon"
                  src="../../../images/icons/down-arrow-white.png"
                  alt="dropdown"
                  color="white"
                />
              ) : null}
            </div>
            {showDescription
              && (
                <div className="mobile-description" style={{ marginTop: showDescription ? '0' : '20px' }}>
                  {asText(description_below_image.raw)}
                </div>
              )}
          </div>
          <div className="description-below-image">
            <div className="desktop-description">{asText(description_below_image.raw)}</div>
          </div>
        </div>
        <div className="bottom-container">
          <div className="icon">
            <GatsbyImage
              image={prismicIcon}
              alt={prismicIcon?.alt || ''}
              loading="eager"
              objectFit="contain"
            />
          </div>
          <div className="secondary-description-container">
            <div className="secondary-description-title">{asText(secondary_description_title.raw)}</div>
            <div className="secondary-description"><PrismicRichText field={addBreaks(secondary_description.raw)} /></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextOnImageWithDescription;
