import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import GridItem from '../components/slices/GridItem/GridItem';
import Tags from '../components/Tags/Tags';
import FullWidthImageForBlog from '../components/slices/FullWidthImageForBlog/FullWidthImageForBlog';
import Button from '../components/Button/Button';

const BlogPostTemplate = ({ data }) => {
  if (!data) return null;

  const blogPostContent = data.prismicBlogPost;
  const blog = blogPostContent.data || {};

  const allPosts = data.allPrismicBlogPost?.nodes;
  const { tags } = data.prismicBlogPost;

  const uniquePosts = allPosts ? allPosts.filter((value) => value.id !== blogPostContent.id) : [];
  const { lang, type, url } = blogPostContent;
  const alternateLanguages = blogPostContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};

  const blogInfo = {
    title: blog.display_title,
    author: blog.author,
    author_title: blog.author_title,
    date: blog.date,
    preview_image: blog.preview_image,
    image_inside_blog_post: blog.image_inside_blog_post,
  };
  const metaTitle = blogPostContent.data.meta_title || `${blogPostContent.data.display_title.text} | CloudArmy Blog`;

  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <FullWidthImageForBlog blogInfo={blogInfo} type={blogPostContent} />
      <SliceZone slices={blog.body} blogInfo={blogInfo} type={blogPostContent.type} />
      <Tags tags={tags} url={activeDoc.url} />
      <Button />
      <GridItem posts={uniquePosts} relatedArticles />
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($uid: String, $lang: String, $id: String) {
    prismicBlogPost(uid: {eq: $uid}, id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      tags
      data {
        date
        author
        author_title
        display_title {
          raw
          html
          text
        }
        preview_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_inside_blog_post {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyTextInfo {
            id
            slice_type
            slice_label
            primary {
              title_alignment
              description_alignment
              width
              description {
                html
                raw
                text
              }
              padding_top
              description_html {
                text
              }
              pdf_text {
                html
                raw
                text
              }
              pdf_hyperlink {
                url
              }
              styled_html {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              description_color
              title {
                html
                raw
                text
              }
              title_color
              icon {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              image_alignment
              image_with_text {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 200, height: 200)
                  }
                }
              }
              text_with_aligned_image {
                html
                raw
                text
              }
            }
          }
          ... on PrismicBlogPostDataBodyEmbeddedMedia {
            id
            primary {
              media {
                raw
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED,
                        blurredOptions: { width: 250, toFormat: AUTO})
                  }
                }
              }
              title {
                html
                raw
                text
              }
              width
              height
              embed_url
              youtube_id
              title_placement
            }
            slice_label
            slice_type
          }
          ... on PrismicBlogPostDataBodyFullWidthMedia {
            id
            slice_label
            slice_type
            primary {
              title_font_size
              title {
                html
                raw
                text
              }
              title_color
              text_position
              description {
                html
                raw
                text
              }
              description_position
              media {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              alt
            }
          }
          ... on PrismicBlogPostDataBodyFormWithImage {
            id
            slice_type
            slice_label
            primary {
              title {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                raw
                richText
                text
              }
              portal_id
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              google_conversion_name
              form_id
            }
            items {
              area_of_interest
            }
          }
          ... on PrismicBlogPostDataBodyBasicForm {
            id
            slice_label
            slice_type
            primary {
              form_id
              google_conversion_name
              portal_id
              title {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                raw
                richText
                text
              }
              description {
                html
                raw
                richText
                text
              }
            }
          }
          ... on PrismicBlogPostDataBodyNudgestockForm {
            id
            slice_type
            primary {
              description {
                html
                richText
                raw
                text
              }
              form_id
              google_conversion_name
              portal_id
              privacy_text {
                html
                raw
                richText
                text
              }
              text_after_submit {
                html
                raw
                richText
                text
              }
              title {
                html
                raw
                richText
                text
              }
            }
            slice_label
          }
        }
      }
      type
    }
    allPrismicBlogPost(
      sort: { fields: uid, order: DESC }
  ) {
    nodes {
      _previewable
      id
      url
      tags
      data {
        display_title {
          raw
          html
          text
        }
        date
        author
        author_title
        preview_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(height: 350, layout: CONSTRAINED, placeholder: NONE,
                  blurredOptions: { width: 250, toFormat: AUTO})
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyGridItem {
            id
            primary {
              title {
                html
                raw
                text
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 350, layout: CONSTRAINED, placeholder: BLURRED,
                        blurredOptions: { width: 250, toFormat: AUTO})
                  }
                }
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
    prismicNavigation(lang: {eq: $lang}) {
    ...NavigationFragment
    }
    prismicFooter(lang: {eq: $lang}) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(BlogPostTemplate);
