import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import './FullWidthTextWithImageBlock.scss';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

const FullWidthTextWithImageBlock = ({ slice }) => {
  const {
    alignment,
    title,
    description,
    text_alignment,
    title_color,
    description_color,
    metrics_title,
    metrics_color,
  } = slice.primary;

  const image = getImage(slice.primary.image.localFile);
  const icon = getImage(slice.primary.icon.localFile);
  const fragment = title.text.replace(/ /g, '-').toLowerCase();

  return (
    <section className={`full-width-text-with-image-block ${alignment}`} id={fragment}>
      <div className={`text-container ${text_alignment}`}>
        <div className={`title ${title_color} ${metrics_title ? 'margin-top' : ''}`}>
          <PrismicRichText field={title.raw || []} />
        </div>
        <StaticImage
          src="../../../images/dot-line.png"
          alt="dotline"
          className="dot-line"
          objectFit="contain"
          width={4000}
        />
        <div className={`description ${description_color}`}>
          <PrismicRichText field={description.raw} />
        </div>
        {slice.items[0] && slice.items[0].metrics.text && (
          <div className={`metrics-container ${metrics_color}`}>
            <div className="metric-title">
              {metrics_title || `Important Metrics Of ${asText(title.raw)}`}
              :
            </div>
            <ul>
              {slice.items.map((item, index) => (
                <li className="metrics" key={index}>
                  <span>{asText(item.metrics.raw)}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={`media ${alignment} ${text_alignment}`}>
        {icon
          ? (
            <GatsbyImage
              image={icon}
              className="icon"
              alt={slice.primary.icon.alt}
              loading="eager"
              objectFit="contain"
            />
          )
          : (
            <img
              src={slice.primary.icon.localFile?.publicURL}
              alt={slice.primary.icon?.alt || ''}
              width={70}
              className="icon-svg"
            />
          )}
        <GatsbyImage
          image={image}
          className="image"
          alt={slice.primary.image.alt}
          loading="eager"
          objectFit="unset"
        />
      </div>
    </section>
  );
};

export default FullWidthTextWithImageBlock;
