import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';
import '../stylesheets/pages/homepage.scss';

const HomepageTemplate = ({ data }) => {
  const [showWidget, setShowWidget] = useState(true);

  if (!data) return null;
  const homepage = data.prismicHomepage || {};
  const navigationMenu = data.prismicNavigation || {};
  const footerMenu = data.prismicFooter || {};
  const { widget } = homepage.data;
  const { widget_url } = homepage.data;
  const { lang, type, url } = homepage || {};
  const alternateLanguages = homepage.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const metaTitle = homepage.data.meta_title;
  return (
    <Layout
      navigationMenu={navigationMenu.data}
      activeDocMeta={activeDoc}
      footerMenu={footerMenu.data}
      metaTitle={metaTitle}
    >
      <SliceZone slices={homepage.data.body} />
      {widget && showWidget && (
        <div className="widget-container">
          <div onClick={() => setShowWidget(false)}>
            <StaticImage
              className="nudgestock-close"
              src="../images/nudgestock/close.png"
              alt="Nudgestock"
              objectFit="contain"
            />
          </div>
          <Link to={widget_url?.document?.url}>

            <StaticImage
              className="nudgestock-n"
              src="../images/nudgestock/2024.png"
              alt="Nudgestock"
              objectFit="contain"
            />
          </Link>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
    query homepageQuery($lang: String, $isProduction: Boolean!) {
        prismicHomepage(lang: { eq: $lang }) {
            alternate_languages {
                uid
                type
                lang
            }
            lang
            url
      type
      _previewable
      data {
        meta_title
        widget
        widget_url {
          document {
            ... on PrismicPage {
              url
            }
          }
        }
        body {
          ... on PrismicHomepageDataBodyFullWidthMedia {
            id
            slice_label
            slice_type
            primary {
              description {
                html
                raw
                text
              }
              title_font_size
              media {
                url
                localFile @include(if: $isProduction) {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      blurredOptions: { width: 250, toFormat: AUTO }
                    )
                  }
                  publicURL
                }
              }
              media {
                raw
                url
                uid
                type
                size
                link_type
              }
              title {
                html
                raw
                text
              }
              text_position
              title_position
              title_color
            }
          }
          ... on PrismicHomepageDataBodyFullWidthVideo {
            id
            slice_label
            slice_type
            primary {
              title {
                html
                raw
                text 
              }
              title_color
              description {
                html
                raw
                text
              }
              description_color
              title_above_description
              video {
                url
                localFile @include(if: $isProduction) {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      blurredOptions: { width: 250, toFormat: AUTO }
                    )
                  }
                  publicURL
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyTextInfo {
            id
            primary {
              description {
                html
                raw
                text
              }
              title_color
              width
              icon {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 100, height: 100, placeholder: NONE)
                  }
                }
                url
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyTextWithAction {
            id
            primary {
              text {
                html
                text
                raw
              }
              title {
                html
                raw
                text
              }
              title_color
              description_color
              alignment
              text_width
              logo {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                  }
                }
                url
              }
            }
            slice_label
            slice_type
            items {
              link {
                url
                raw
                id
                link_type
                document {
                  ... on PrismicPage {
                    url
                  }
                  ... on PrismicBlog {
                    url
                  }
                  ... on PrismicPodcast {
                    url
                  }
                  ... on PrismicKnowledgeCenter {
                    url
                  }
                  ... on PrismicContact {
                    url
                  }
                }
              }
              link_title {
                html
                raw
                text
              }
              fragment
            }
          }
          ... on PrismicHomepageDataBodyConceptSnippet {
            id
            primary {
              description {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
              title_color
              description_color
            }
            items {
              learn_more
              url {
                document {
                  ... on PrismicPage {
                    url
                  }
                }
              }
              fragment
              description {
                html
                raw
                text
              }
              icon {
                alt
                copyright
                dimensions {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                  publicURL
                }
                url
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyMediaDescription {
            id
            primary {
              description {
                html
                raw
                text
              }
              media {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                  }
                }
                url
              }
              youtube_id
              embed_url
              width
              height
              title {
                html
                raw
                text
              }
              title_color
              description_color
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyExpandableDescriptionList {
            id
            primary {
              alignment
              title {
                html
                raw
                text
              }
              learn_more
              findability_icon
              background_image {
                url
                localFile @include(if: $isProduction) {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
                alt
                copyright
              }
            }
            slice_label
            slice_type
            items {
              description {
                html
                raw
                text
              }
              text {
                html
                raw
                text
              }
              link {
                document {
                  ... on PrismicPage {
                    url
                  }
                }
              }
              fragment
            }
          }
          ... on PrismicHomepageDataBodyTextWithIcons {
            id
            items {
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
                copyright
                alt
                url
              }
              icon_title {
                html
                raw
                text
              }
              bottom_text {
                html
                raw
                text
              }
            }
            primary {
              title {
                html
                raw
                text
              }
              fragment
              link {
                document {
                  ... on PrismicPage {
                    url
                  }
                }
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyImageGrid {
            id
            items {
              description {
                html
                raw
                text
              }
              image {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 160
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
                url
              }
              image_title {
                html
                raw
                text
              }
            }
            primary {
              title {
                html
                raw
                text
              }
              title_color
              solutions_or_about
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyQuoteCarousel {
            id
            items {
              quotes {
                html
                raw
                text
              }
              image {
                url
                localFile @include(if: $isProduction) {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            primary {
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodySocialIcons {
            id
            items {
              icon {
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                  }
                }
              }
              url {
                url
                raw
                link_type
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...NavigationFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`;

export default withPrismicPreview(HomepageTemplate);
