import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './FullWidthImageWithButtons.scss';

const FullWidthImageWithButtons = ({ slice, blogInfo, type }) => {
  const {
    title,
    title_color,
    sub_title,
    sub_title_color,
    description,
    description_color,
    image,
    mobile_image,
  } = slice.primary;

  const desktopImage = getImage(image.localFile);
  const mobileImage = getImage(mobile_image.localFile);

  const scrollAndFocus = (element, where) => {
    element.scrollIntoView({behavior: 'smooth', block: 'start'});
    where.focus({preventScroll: true});
  }
  const formWrapper = typeof window !== 'undefined' ? document.getElementById('form-wrapper') : null;
  const firstNameFocus = typeof window !== 'undefined' ? document.getElementById('first-name') : null;
  return (
    <section className="full-width-image-with-buttons">
      <div className="image-container">
        <GatsbyImage
          className={`desktop-image ${mobileImage ? '' : 'no-mobile'}`}
          image={desktopImage}
          alt={desktopImage?.alt || ''}
          loading="eager"
          objectFit="none"
        />
        {mobileImage ? <GatsbyImage
          className="mobile-image"
          image={mobileImage}
          alt={mobileImage?.alt || ''}
          loading="eager"
          objectFit="cover"
          objectPosition="0% 90%"
        /> : null}
        <div className="act-now" onClick={() => scrollAndFocus(formWrapper, firstNameFocus)} />
      </div>
      {(title?.raw.length || sub_title?.raw.length || description?.raw.length) ? <div className="text-container">
        <div className="title" style={{ color: title_color || 'white' }}><PrismicRichText field={title?.raw || []} /></div>
        <div className="sub-title" style={{ color: sub_title_color || 'white' }}><PrismicRichText field={sub_title?.raw || []} /></div>
        <div className="description" style={{ color: description_color || 'white' }}><PrismicRichText field={description?.raw || []} /></div>
        <div className="button-wrapper">
        {slice.items.map((item, index) => {
          const element = item.button_fragment?.text && typeof window !== 'undefined' ? document.getElementById(item.button_fragment.text) : null;
          const firstNameFocus = item.button_fragment?.text && typeof window !== 'undefined' ? document.getElementById('first-name') : null;
          return (
            <div key={index}>
              {item.button_enabled ? 
              <a onClick={() => scrollAndFocus(element, firstNameFocus)}
                style={{ cursor: 'pointer' }}>
                <div className="button" style={{color: item.button_text_color, backgroundColor: item.button_color}} key={index}>
                  {asText(item.button_text.raw)}
                </div>
              </a>
              : null
              }
          </div>
          )}
        )}
      </div>
      </div> : null}
    </section>
  );
};

export default FullWidthImageWithButtons;
