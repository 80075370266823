import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './FullWidthImageWithTextBelow.scss';
import Stream from '../Stream/Stream';

const FullWidthImageWithTextBelow = ({ slice, blogInfo, type }) => {
  const {
    title,
    title_color,
    description,
    description_color,
    image,
    has_stream_buttons,
    description_position,
  } = slice.primary;

  const fullWidthImage = getImage(image.localFile);
  const marginTopStyle = !fullWidthImage ? '5%' : description?.raw.length ? '-10%' : '-5%';
  return (
    <section className="full-width-image-with-text-below-container">
      <div className="image-container">
        <GatsbyImage
          className="image"
          image={fullWidthImage}
          alt={image.alt || ''}
          loading="eager"
          objectFit="cover"
        />
      </div>
      {(title?.raw.length || description?.raw.length) ? <div className="text-container" style={{ marginTop: marginTopStyle }}>
        {title?.raw.length
          ? (
            <div className="full-width-image-with-text-below-title" style={{ color: title_color }}>
              <PrismicRichText field={title.raw || []} />
            </div>
          )
          : null}
        {description?.raw.length 
        ? (
          <div className="full-width-image-with-text-below-description" style={{ color: description_color, textAlign: description_position || 'start' }}>
            <PrismicRichText field={description.raw || []} />
          </div>
        )
        : null}
        {has_stream_buttons
          ? <Stream />
          : null}
      </div> : null}
    </section>
  );
};

export default FullWidthImageWithTextBelow;
