import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import './TextWithAction.scss';
import { Link } from 'gatsby';

export default function Hover({
  to,
  text,
  description,
  background,
  fragment,
  link_opens_to_a_new_tab,
}) {
  return (
    <div className="button-wrapper">
      {
        link_opens_to_a_new_tab
          ? (
            <a
              className={`button ${background === 'blue-background' ? 'padding' : ''}`}
              href={fragment ? `${to}#${fragment}` : to || ''}
              target="_blank"
              rel="noreferrer"
            >
              {asText(text).toUpperCase()}
              {description && description.text.length > 0 && (
                <div className="description">
                  {asText(description.raw).toUpperCase()}
                </div>
              )}
            </a>
          )
          : (
            <Link
              className={`button ${background === 'blue-background' ? 'padding' : ''}`}
              to={fragment ? `${to}#${fragment}` : to || ''}
            >
              {asText(text).toUpperCase()}
              {description && description.text.length > 0 && (
                <div className="description">
                  {asText(description.raw).toUpperCase()}
                </div>
              )}
            </Link>
          )
      }
      <span className="hover-circle" />
    </div>
  );
}
