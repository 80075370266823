import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import '@fontsource/roboto-mono';
import '@fontsource/montserrat';

// adding these imports here includes them in the commons.js bundle
import { PrismicRichText } from '@prismicio/react';
import { Helmet } from 'react-helmet';
import { DebounceInput } from 'react-debounce-input';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';

import linkResolver from './src/utils/linkResolver';
import PageTemplate from './src/templates/Page';
import HomepageTemplate from './src/templates/Homepage';
import BlogPost from './src/templates/BlogPost';
import KnowledgeCenterArticle from './src/templates/KnowledgeCenterArticle';
import WebinarPost from './src/templates/WebinarPost/WebinarPost';
import PodcastPost from './src/templates/PodcastPost/PodcastPost';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'cloudarmy-website',
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          homepage: HomepageTemplate,
          prismicPage: PageTemplate,
          prismicHomepage: HomepageTemplate,
          blog_post: BlogPost,
          knowledge_center_article: KnowledgeCenterArticle,
          webinar_post: WebinarPost,
          podcast_post: PodcastPost,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
