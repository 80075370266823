import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { asText } from '@prismicio/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TagManager from 'react-gtm-module';

const LandingPageForm = ({ 
  consent, onSend, slice, title, description, title_color, description_color, landing_page_title_background_image,
  google_conversion_name
 }) => {
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm();
  
  const [captchaValue, setCaptchaValue] = useState("");
  const [notVerified, setNotVerified] = useState(false);

  const [formData, setFormData] = useState({
    fields: [
      {
        name: 'firstname',
        value: '',
      },
      {
        name: 'email',
        value: '',
      },
    ],
  });

  const handleHubspotSubmit = (event) => {
    if (captchaValue?.length) {
      const timestamp = new Date();
      // const milliseconds = timestamp.getTime();

      const xhr = new XMLHttpRequest();
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${slice.primary.portal_id}/${slice.primary.form_id}`;

      // formData.submittedAt = milliseconds.toString();
      formData.legalConsentOptions = consent;

      const finalData = JSON.stringify(formData);

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
        onSend(); // success
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          alert(xhr.responseText); // Returns a 404 error if the formGuid isn't found
        }
      };
      xhr.send(finalData);
      window.dataLayer.push({
        'event': google_conversion_name,
        'hs-form-guid': slice.primary.form_id,
      });
    } else {
      setNotVerified(true);
    }
  };

  const select = (event) => {
    event.preventDefault();
    formData.fields[6].value = event.target.value;
  };

  const setData = (key, value) => {
    const objIndex = formData.fields.findIndex(((obj) => obj.name === key));
    formData.fields[objIndex].value = value;
  };

  const firstNameRegister = register('firstname', { required: true });
  const emailRegister = register('email', { required: true });

  const onReCAPTCHA = (value) => {
    if (value) {
      setNotVerified(false);
    }
    setCaptchaValue(value);
  }

  return (
    <form className="landing-page-form-container" onSubmit={handleSubmit(handleHubspotSubmit)}>
      <div className="landing-page-form-text-container">
        <GatsbyImage
            className="landing-page-title-background-image"
            image={getImage(landing_page_title_background_image.localFile)}
            loading="eager"
            objectFit="cover"
            alt={landing_page_title_background_image.alt || ''}
          />
        <div className="landing-page-form-only-text-container">
          <div className={`landing-page-form-title ${title_color}`}>
            {asText(title.raw)}
          </div>
          <div className={`landing-page-form-description ${description_color}`}>
            {asText(description.raw)}
          </div>
        </div>
      </div>
      <div className="landing-page-form-wrapper">
        <div className="landing-page-form-input">
          <div className="landing-page-form-first-name-input">
            <div className="first-name-label">Your Name</div>
            <input
              className={`first-name ${errors.firstname?.type}`}
              id="first-name"
              type="text"
              placeholder=""
              {...firstNameRegister}
              onChange={(e) => {
                firstNameRegister.onChange(e);
                setData('firstname', e.target.value)
              }}
            />
          </div>
          <div className="landing-page-form-email-input">
            <div className="email-label">Your Email Address</div>
            <input
              className={`email ${errors.email?.type}`}
              type="text"
              placeholder=""
              {...emailRegister}
              onChange={(e) => {
                emailRegister.onChange(e)
                setData('email', e.target.value)
              }}
            />
          </div>
          <div className="button-wrapper">
            <input
              className="submit"
              type="submit"
              value="Subscribe Now"
            />
            <span className="hover-circle normal" />
            <div className={`captcha ${notVerified ? 'not-verified' : 'verified'}`}>
              <ReCAPTCHA
                sitekey="6LfsCP8fAAAAANoUgdFHzLdc7CxAUkPLd-lfPA77"
                onChange={onReCAPTCHA}
                className="captcha"
              />
            </div>
            <div className="terms-text">By clicking Subscribe now, you agree to our <a className="terms-link" href="https://cloud.army/terms">Terms and Conditions </a>and receiving emails in the email address you provided.</div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LandingPageForm;