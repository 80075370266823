import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Carousel from './Carousel';
import PrismicImage from '../../PrismicImage/PrismicImage';
import './QuoteCarousel.scss';

const QuoteCarousel = ({ slice }) => (
  <section className="quote-carousel">
    <Carousel show={1}>
      {slice.items.map((item, index) => {
        const image = getImage(item.image.localFile);
        return (
          <div className="quote-container" key={index}>
            <div className="quote-container-content">
              {image && (
                <GatsbyImage
                  image={image}
                  alt="placeholder"
                  className="image"
                  objectFit="scale-down"
                />
              )}
              {/* {image && <div className="image-wrapper"><PrismicImage image={item.image} /></div>} */}
              <div className="quote">
                <PrismicRichText field={item.quotes.raw || []} />
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  </section>

);
export default QuoteCarousel;
