import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Hover from './Hover';
import './TextWithAction.scss';

const TextWithAction = ({ slice }) => {
  const {
    logo_above_text,
    title_color,
    description_color,
    title,
    alignment,
    text,
    background,
    text_width,
    fragment,
  } = slice.primary;
  
  const logo = slice.primary.logo?.localFile ? getImage(slice.primary.logo.localFile) : null;
  const link = slice.items[0].link_title.text;
  const singleButton = slice.items.length === 1;

  const ConditionalText = () => (
    <div className={`conditional ${logo_above_text} ${background}`}>
      <div className="logo-placement">
        {title && title.text.length > 0 && (
          <div className={`title ${title_color}`}>
            <PrismicRichText field={title.raw || []} />
          </div>
        )}
        {text && text.text.length > 0 && (
          <div className={`text ${alignment} ${description_color} ${text_width}`}>
            <PrismicRichText field={text.raw || []} />
          </div>
        )}
        {singleButton && link && (
          <Hover
            to={slice.items[0].link.document?.url || slice.items[0].link.url}
            text={slice.items[0].link_title.raw}
            fragment={slice.items[0].fragment}
            link_opens_to_a_new_tab={slice.items[0].link_opens_to_a_new_tab}
          />
        )}
        <div className="multiple-buttons">
          {!singleButton && slice.items.map((item, index) => (
            <Hover
              to={item.link.document?.url || slice.items[0].link.url}
              text={item.link_title.raw}
              key={index}
              description={item.description}
              background={background}
              fragment={item.fragment}
              link_opens_to_a_new_tab={item.link_opens_to_a_new_tab}
            />
          ))}
        </div>
      </div>
      {logo && (
        <div className="logo">
          <GatsbyImage image={logo} alt="" />
        </div>
      )}
    </div>
  );
  return (
    <section className="text-with-action">
      <ConditionalText />
    </section>
  );
};

export default TextWithAction;
